import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { memberWrapper } from '@wix/social-groups-api';
import { SocialApiTypes } from '@wix/social-groups-api/dist/src/types';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import {
  Loader,
  Modal,
  ModalSkin,
  withTPAConfig,
  Spinner,
  Button,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  editorStateToRaw,
  isEditorStateEmpty,
  editorStateFromRawContent,
} from '@wix/social-groups-common/dist/src/components/ContentEditor/utils';
import { WithGroup, WithGroupProps } from '../../Context';

import styles from './NewPostModal.st.css';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import {
  EditorState,
  RawDraftContentState,
} from 'wix-rich-content-editor-common';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import { ModalParentNodeRefProvider } from '@wix/social-groups-common/dist/src/components/Modal/ModalParentNodeRefContext';

const PostContentEditor = React.lazy(() =>
  import('../../PostContentEditor/PostContentEditor'),
);

interface NewPostModalProps {
  isOpen: boolean;
  isPostPublishing: boolean;
  initialContentState?: RawDraftContentState;

  onSubmit(
    post: RawDraftContentState,
    attachments?: SocialApiTypes.Resource[],
  ): void;

  onVisibilityChange(isOpen: boolean): void;

  onSaveDraft?(post: RawDraftContentState);
}

interface NewPostModalState {
  editorState: EditorState;
}

type Props = NewPostModalProps &
  InjectedTranslateProps &
  WithGroupProps &
  TPAComponentsConfig;

class NewPostModalComponent extends React.Component<Props, NewPostModalState> {
  static defaultProps = {
    initialContentState: null,
    onSaveDraft() {},
    currentMember: {
      name: {},
    },
  };
  $parent = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.state = {
      editorState: props.initialContentState
        ? editorStateFromRawContent(props.initialContentState)
        : null,
    };
  }

  handleChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  handleSubmit = () => {
    const { editorState } = this.state;

    this.props.onSaveDraft(null);
    this.props.onSubmit(editorStateToRaw(editorState));
  };

  handleClose = () => {
    const { editorState } = this.state;
    const isEmpty = isEditorStateEmpty(editorState);

    this.props.onSaveDraft(isEmpty ? null : editorStateToRaw(editorState));
    this.props.onVisibilityChange(false);
  };

  handleCancel = () => {
    this.props.onSaveDraft(null);
    this.props.onVisibilityChange(false);
  };

  renderPostContentEditor = () => {
    const { group, mobile } = this.props;
    const { editorState } = this.state;

    return (
      <div
        ref={this.$parent}
        className={styles.rceWrapper}
        data-hook="new-post-modal"
      >
        <ModalParentNodeRefProvider value={this.$parent.current}>
          <React.Suspense fallback={<Spinner />}>
            <PostContentEditor
              isMobile={mobile}
              initialEditorState={
                this.props.initialContentState ? editorState : null
              }
              group={group}
              onChange={this.handleChange}
            />
          </React.Suspense>
        </ModalParentNodeRefProvider>
      </div>
    );
  };

  isPostButtonDisabled = () => {
    return isEditorStateEmpty(this.state.editorState) || this.isLoading();
  };

  renderDesktopLayout = () => {
    const { t, currentMember, isPostPublishing } = this.props;
    const { name, imageUrl } = memberWrapper(currentMember);

    return (
      <>
        <div className={styles.profileInfo}>
          <Avatar
            src={imageUrl}
            size={AvatarSize.large}
            {...styles('avatar')}
          />
          <Text className={styles.currentMemberName}>
            {name.nick || t('groups-web.member.anonymous')}
          </Text>
        </div>
        {this.renderPostContentEditor()}
        <div className={styles.actionButtons}>
          <Button className={styles.cancelButton} onClick={this.handleCancel}>
            {t('groups-web.discussion.new-post.cancel')}
          </Button>
          <Button
            priority={PRIORITY.primary}
            disabled={this.isPostButtonDisabled()}
            onClick={this.handleSubmit}
            prefixIcon={this.isLoading() && <Loader />}
          >
            {isPostPublishing
              ? t('groups-web.discussion.new-post.publishing')
              : t('groups-web.discussion.new-post.publish')}
          </Button>
        </div>
      </>
    );
  };

  renderMobileLayout = () => {
    const { t } = this.props;
    return (
      <>
        <div className={styles.mobileHeader}>
          <TextButton
            onClick={this.handleClose}
            priority={TEXT_BUTTON_PRIORITY.secondary}
          >
            {t('groups-web.discussion.new-post.mobile.back')}
          </TextButton>
          <Text
            typography={TYPOGRAPHY.smallTitle}
            className={styles.mobileTitle}
          >
            {t('groups-web.discussion.new-post.mobile.title')}
          </Text>
          <TextButton
            onClick={this.handleSubmit}
            priority={TEXT_BUTTON_PRIORITY.primary}
            disabled={this.isPostButtonDisabled()}
          >
            {t('groups-web.discussion.new-post.mobile.post')}
          </TextButton>
        </div>
        {this.renderPostContentEditor()}
      </>
    );
  };

  render() {
    const { isOpen, mobile } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        withCloseButton={!mobile}
        skin={ModalSkin.NEW_POST}
      >
        <div
          {...styles('root', { mobile }, this.props)}
          data-hook="new-post-modal"
        >
          {mobile ? this.renderMobileLayout() : this.renderDesktopLayout()}
        </div>
      </Modal>
    );
  }

  private isLoading() {
    const { isPostPublishing, updateProgress } = this.props;
    return isPostPublishing || updateProgress !== UpdateProgress.STALE;
  }
}

const enhance = compose(WithGroup, translate(), withTPAConfig);

export const NewPostModal = enhance(NewPostModalComponent);
