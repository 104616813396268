import * as React from 'react';
import { GroupActionsConsumer } from './GroupActionsContext';

export interface MembershipAction {
  joinGroup(): void;
  leaveGroup(): void;
  withdrawJoinRequest(): void;
}

export interface MembershipChangeAction {
  changeMembership(biOrigin: string): void;
  openJoinDialog(): void;
}

export const withMembershipChangeAction = <P extends MembershipChangeAction>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> => props => (
  <GroupActionsConsumer>
    {({ changeMembership, openJoinDialog }) => (
      <WrappedComponent
        {...props}
        changeMembership={changeMembership}
        openJoinDialog={openJoinDialog}
      />
    )}
  </GroupActionsConsumer>
);
