import * as React from 'react';

import { InjectedTranslateProps, translate } from 'react-i18next';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { ShareModal } from '@wix/social-groups-common/dist/src/components/ShareModal';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { wixMediaApi } from '@wix/social-groups-common/dist/src/WixMediaApi';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';

import { ArrowBackIcon } from '../../icons/ArrowBackIcon';
import { CoverImageLayout } from '../../../settings';

import {
  withAppData,
  WithAppDataProps,
  withAppSettings,
  WithAppSettingsProps,
  WithGroup,
  WithGroupActionProps,
  WithGroupActions,
  WithGroupProps,
} from '../Context';
import { InviteMembers } from '../InviteMembers/InviteMembers';
import { MembershipButton } from '../Membership';
import { ShareButton } from '../Share';
import { GroupActions } from '../GroupActions';
import { Details } from '../Details';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

import styles from './Header.st.css';
import { isGroupSecret } from '@wix/social-groups-api';

interface HeaderState {
  isShareModalOpen: boolean;
}

type HeaderProps = WithGroupProps &
  WithAppSettingsProps &
  WithGroupActionProps &
  WithTpaComponentsConfigProps &
  WithBiLoggerProps &
  WithAppDataProps &
  InjectedTranslateProps;

function getImageWidth(mobile, coverImageLayout) {
  return mobile ? 750 : coverImageLayout === CoverImageLayout.large ? 940 : 100;
}

function getImageHeight(mobile, coverImageLayout) {
  return mobile ? 160 : coverImageLayout === CoverImageLayout.large ? 240 : 88;
}

export class HeaderComponent extends React.Component<HeaderProps, HeaderState> {
  state: HeaderState = { isShareModalOpen: false };
  private readonly wixMediaApi = wixMediaApi();

  render() {
    const {
      appSettings,
      goToGroupList,
      group: {
        details: { logoUrl },
      },
      mobile,
    } = this.props;

    const { coverImageLayout } = appSettings.generalLayout;
    const withImage = coverImageLayout !== CoverImageLayout.blank;

    const logoUrlResized =
      logoUrl &&
      this.wixMediaApi.resizeUrl(
        logoUrl,
        getImageWidth(mobile, coverImageLayout),
        getImageHeight(mobile, coverImageLayout),
      );

    return (
      <div
        {...styles('root', {
          withImage,
          mobile,
          coverImageLayout,
        })}
      >
        {mobile ? (
          <div className={styles.mobileActionsHolder}>
            {withImage ? (
              <div className={styles.buttonsOverlayOnImage} />
            ) : null}
            <div className={styles.mobileActions}>
              <div className={styles.mobileActionsContainer}>
                <div className={styles.leftButtonsBlock}>
                  <IconButton
                    data-hook="arrow-back-button"
                    skin={Skins.Full}
                    icon={<ArrowBackIcon width="24px" height="24px" />}
                    className={styles.arrowBackIcon}
                    onClick={() => {
                      // need to omit event passing
                      goToGroupList();
                    }}
                  />
                </div>
                <div className={styles.rightButtonsBlock}>
                  {this.renderShareButton()}
                  {this.renderGroupActions(styles.groupActionsButtonMobile)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.image}>
          {withImage ? (
            <div
              data-hook="group-image"
              className={styles.largeImage}
              style={{
                backgroundImage: `url(${logoUrlResized})`,
              }}
            />
          ) : null}
        </div>
        <div className={styles.groupInfo}>
          <Details />
        </div>
        <div className={styles.groupActions}>
          <div className={styles.groupActionsContent}>
            <InviteMembers />
            <MembershipButton
              className={styles.membershipButton}
              biOrigin="group_feed_top_banner_btn"
            />
            {!mobile ? (
              <>
                {this.renderShareButton()}
                {this.renderGroupActions(styles.threeDotsIcon)}
              </>
            ) : null}
          </div>
        </div>
        {this.renderShareModal()}
      </div>
    );
  }

  private renderShareModal() {
    const { t, location } = this.props;
    const shareUrl = location && location.url;
    return (
      <ShareModal
        isOpen={this.state.isShareModalOpen}
        onRequestClose={this.closeShareModal}
        title={t('groups-web.group.actions.share.group')}
        shareUrl={shareUrl}
      />
    );
  }

  private renderShareButton() {
    const { group, mobile } = this.props;

    if (isGroupSecret(group)) {
      return null;
    }

    const style = mobile
      ? styles(styles.shareButtonMobile)
      : styles(styles.shareButton);
    return (
      <ShareButton onClick={() => this.openShareModal('arrow')} {...style} />
    );
  }

  private renderGroupActions(iconClassName?: string) {
    return (
      <GroupActions
        onShare={() => this.openShareModal('menu')}
        iconClassName={iconClassName}
      />
    );
  }

  private readonly closeShareModal = () =>
    this.setState({ isShareModalOpen: false });

  private readonly openShareModal = (biOrigin: string) => {
    const { biLogger, group } = this.props;
    this.setState({ isShareModalOpen: true });
    tryToCallBi(async () => {
      await biLogger.groupActionClick({
        action: 'share',
        group_id: group.groupId,
        origin: biOrigin,
      });
    });
  };
}

const enhance = compose(
  translate(),
  WithGroup,
  WithGroupActions,
  withTpaComponentsConfig,
  withAppSettings,
  withBiLogger,
  withAppData,
);

export const Header = enhance(HeaderComponent) as React.ComponentType;
