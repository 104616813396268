import * as React from 'react';

import {
  ContentEditorComponent,
  ContentEditorProps,
} from '@wix/social-groups-common/dist/src/components/ContentEditor';

import { withAppData, WithAppDataProps } from '../Group/Context';

import getTheme from './theme';

export interface EditorComponentProps extends ContentEditorProps {
  isMobile?: boolean;
}
interface ThemedContentEditorProps
  extends WithAppDataProps,
    EditorComponentProps {}

export const ThemedContentEditor: React.FunctionComponent<ThemedContentEditorProps> = (
  props: ThemedContentEditorProps,
) => {
  const { isRTL, isMobile, ...rest } = props;
  const theme = getTheme();
  const textDirection = isRTL ? 'rtl' : 'ltr';
  return (
    <ContentEditorComponent
      {...rest}
      theme={theme}
      textDirection={textDirection}
      autoFocus={true}
      isMobile={isMobile}
    />
  );
};

export const ContentEditor = withAppData(
  ThemedContentEditor,
) as React.ComponentType<EditorComponentProps>;
