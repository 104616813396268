import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { debounce } from 'lodash';
import { ProGallery } from 'pro-gallery';
import GALLERY_CONSTS from 'pro-gallery/dist/src/common/constants';

import { ProFullscreen } from '@wix/pro-fullscreen-renderer';
import EVENTS from 'pro-gallery/dist/src/common/constants/events';
import FULLSCREEN_EVENTS from '@wix/pro-fullscreen-renderer/dist/src/constants/fullscreenEvents';
import { MediaApi, Anonymous } from '@wix/social-groups-api';
import {
  ApiTypes,
  SocialApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { withTPAConfig } from '@wix/social-groups-common';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import {
  WithEditorSettingsProps,
  WithEditorSettings,
} from '@wix/editor-settings';

import {
  WithGroup,
  WithGroupActionProps,
  WithGroupActions,
  WithGroupProps,
  withSiteMembers,
  withAppSettings,
  SiteMembers,
  WithAppSettingsProps,
  MembersActions,
} from '../Context';
import { IFeedItem, IAttachment } from '../../../controllers/types';
import { MediaLayout } from '../../../settings/types';
import { EmptyState } from '../EmptyState';

import { Thumbnail } from './Thumbnail';

import styles from './Media.st.css';
import './Media.global.css';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

export interface MediaProps {
  onCreatePostClick(): void;
}

interface MediaState {
  isInitializing: boolean;
  activeId: number;
}

const MediaLayoutMap = {
  [MediaLayout.grid]: GALLERY_CONSTS.layout.GRID,
  [MediaLayout.collage]: GALLERY_CONSTS.layout.COLLAGE,
  [MediaLayout.masonry]: GALLERY_CONSTS.layout.MASONRY,
};

interface AttachmentWithAuthor extends IAttachment {
  author: ApiTypes.v1.GroupMemberResponse;
}

type MediaComponentProps = MediaProps &
  WithGroupProps &
  SiteMembers &
  MembersActions &
  WithGroupActionProps &
  InjectedTranslateProps &
  WithEditorSettingsProps &
  TPAComponentsConfig &
  WithBiLoggerProps &
  WithAppSettingsProps;

export class MediaComponent extends React.Component<
  MediaComponentProps,
  MediaState
> {
  readonly state: MediaState = {
    isInitializing: true,
    activeId: -1,
  };

  GALLERY_STYLES = {
    titlePlacement: GALLERY_CONSTS.placements.SHOW_ON_HOVER,
    allowTitle: true,
    allowDescription: true,
    allowDescriptionExpand: false,
    itemClick: GALLERY_CONSTS.itemClick.EXPAND,

    enableInfiniteScroll: true,
    galleryLayout:
      MediaLayoutMap[this.props.appSettings.mediaLayout.mediaLayout],
    gallerySizeType: GALLERY_CONSTS.gallerySizeType.SMART,
    gridStyle: 1,
    floatingImages: 0,
    numberOfImagesPerRow: this.props.appSettings.mediaLayout.imagesPerRow,
    loadMoreAmount: GALLERY_CONSTS.loadMoreAmount.PARTIAL,

    gallerySize: 30,
    galleryMargin: 0,
    imageMargin: this.props.appSettings.generalLayout.layoutSpacing,

    cubeType: GALLERY_CONSTS.cubeType.CROP,

    cubeRatio: 1,
    cubeImages: true,
    videoLoop: true,
    videoPlay: GALLERY_CONSTS.videoPlay.HOVER,
    galleryHorizontalAlign: GALLERY_CONSTS.horizontalAlign.CENTER,
    galleryVerticalAlign: GALLERY_CONSTS.verticalAlign.CENTER,
    imageLoadingMode: GALLERY_CONSTS.loadingMode.COLOR,

    hoveringBehaviour: GALLERY_CONSTS.infoBehaviourOnHover.APPEARS,
    scrollAnimation: GALLERY_CONSTS.scrollAnimations.FADE_IN,
    overlayAnimation: GALLERY_CONSTS.overlayAnimations.FADE_IN,
    imageHoverAnimation: GALLERY_CONSTS.imageHoverAnimations.NO_EFFECT,
    expandAnimation: GALLERY_CONSTS.expandAnimations.FADE_IN,

    bgColorExpand: {
      value: this.props.settings.getColorByRef('color-1').value,
    },
    actionsColorExpand: {
      value: this.props.settings.getColorByRef('color-5').value,
    },
  };

  MOBILE_GALLERY_STYLES = {
    ...this.GALLERY_STYLES,
    galleryLayout: -1,
    gridStyle: 0,
    gallerySize: 0,
    groupTypes: '3',
  };

  containerRef = React.createRef<HTMLDivElement>();

  fetchMore = debounce(this.props.feed.fetchMore, 200).bind(this);

  handleGalleryEvents = (eventName: string, data) => {
    switch (eventName) {
      case EVENTS.NEED_MORE_ITEMS:
        this.fetchMore();
        break;
      case EVENTS.ITEM_ACTION_TRIGGERED:
        this.setState({ activeId: data.idx });
        break;
      case EVENTS.ITEM_LOADED:
        this.setState({ isInitializing: false });
        break;
      case FULLSCREEN_EVENTS.CLOSE_FULLSCREEN:
        this.setState({ activeId: -1 });
        break;

      default:
    }
  };
  private getMediaUrl(uri: string) {
    try {
      return new URL(uri) && uri;
    } catch (e) {
      return MediaApi.wixStaticFileUrl(uri);
    }
  }

  mapMediaItem = (attachment: AttachmentWithAuthor) => {
    let item;

    switch (attachment.mediaType) {
      case SocialApiTypes.MediaType.IMAGE:
        item = {
          itemId: attachment.id,
          mediaUrl: this.getMediaUrl(attachment.uri),
          metaData: {
            type: 'image',
            height: attachment.pixelDimensions.height,
            width: attachment.pixelDimensions.width,
            description: JSON.stringify(attachment.author),
          },
        };
        break;

      case SocialApiTypes.MediaType.VIDEO:
        item = {
          itemId: attachment.id,
          mediaUrl: MediaApi.wixStaticVideoUrl(attachment.uri),
          metaData: {
            type: 'video',
            height: attachment.pixelDimensions.height,
            width: attachment.pixelDimensions.width,
            description: JSON.stringify(attachment.author),
          },
        };
        break;

      default:
        item = {};
    }

    return item;
  };

  getGalleryItems() {
    const { feed, siteMembersMap } = this.props;

    return feed.feedItems
      .map(populateWithAuthor)
      .reduce((acc, val) => acc.concat(val), [])
      .map(this.mapMediaItem)
      .filter(attachment => !!attachment.itemId);

    function populateWithAuthor(feedItem: IFeedItem) {
      return feedItem.attachments.map(attachment => ({
        ...attachment,
        author: siteMembersMap[feedItem.createdBy] || Anonymous,
      }));
    }
  }

  getThumbnail() {
    const { appSettings } = this.props;
    return appSettings.mediaDisplay.showNameOnHover ? Thumbnail : () => <div />;
  }

  handleCreatePostClick = () => {
    const { group, biLogger, onCreatePostClick } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupCreatePostClick({
        origin: 'media_tab_btn',
        group_id: group.groupId,
      });
    });
    onCreatePostClick();
  };

  render() {
    const { t, mobile, feed } = this.props;
    const { isInitializing, activeId } = this.state;

    if (feed.feedLoading) {
      return (
        <Text typography={TYPOGRAPHY.runningText} tagName={'p'}>
          {t('groups-web.loading')}
        </Text>
      );
    }

    const items = this.getGalleryItems();

    if (!items.length) {
      return (
        <EmptyState
          {...styles('emptyRoot')}
          title={t('groups-web.media.empty.title')}
          content={t('groups-web.media.empty.description')}
          button={{
            label: t('groups-web.discussion.create-new'),
            onClick: this.handleCreatePostClick,
          }}
        />
      );
    }

    const container = {
      width: 980,
      height: 100,
      avoidGallerySelfMeasure: true,
    };
    const fullscreenContainer = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    if (this.containerRef.current) {
      container.width = this.containerRef.current.clientWidth;
    }

    const galleryStyles = mobile
      ? this.MOBILE_GALLERY_STYLES
      : this.GALLERY_STYLES;

    return (
      <div ref={this.containerRef} {...styles(styles.root, { mobile })}>
        {isInitializing && <Text>Loading...</Text>}
        <ProGallery
          items={items}
          container={container}
          styles={galleryStyles}
          totalItemsCount={Infinity}
          eventsListener={this.handleGalleryEvents}
          customHoverRenderer={this.getThumbnail()}
        />
        {activeId >= 0 && (
          <section className="pro-fullscreen-wrapper">
            <ProFullscreen
              items={items}
              initialIdx={activeId}
              itemsLoveData={[]}
              container={fullscreenContainer}
              totalItemsCount={Infinity}
              deviceType={mobile ? 'mobile' : 'desktop'}
              styles={galleryStyles}
              eventsListener={this.handleGalleryEvents}
            />
          </section>
        )}
      </div>
    );
  }
}

const enhanced = compose(
  WithEditorSettings,
  WithGroupActions,
  WithGroup,
  withSiteMembers,
  withTPAConfig,
  withAppSettings,
  withBiLogger,
  translate(),
);

export const Media = enhanced(MediaComponent) as React.ComponentType<
  MediaProps
>;

export default Media;
