import { IFeedItem } from './store';
import { SiteMembers } from '../../components/Group/Context';
import { Anonymous } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

export const getPostAuthor = (
  feedItem: IFeedItem,
  siteMembersMap: SiteMembers['siteMembersMap'],
): ApiTypes.v1.GroupMemberResponse =>
  siteMembersMap[feedItem.createdBy] || Anonymous;
