import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import classname from 'classnames';
import { Box, TextButton } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { canInviteMembers, canSeeGroup } from '@wix/social-groups-api';
import { Text } from 'wix-ui-tpa/Text';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { WithGroup, WithGroupProps, withSiteMembers } from '../../Context';
import { WithSiteMembers } from '../../Context/withSiteMembers';
import { MembersList } from './MembersList';
import { MembersWidgetCTAButton } from './MembersWidgetCTAButton';
import { getMembersKey } from '../../../MembersLabel/MembersLabel';

import discussionStyles from '../Discussion.st.css';
import styles from './MembersWidget.st.css';

class MembersWidgetComponent extends React.Component<
  InjectedTranslateProps & WithGroupProps & WithSiteMembers
> {
  render() {
    const { t, group, currentSiteMember, promptLogin } = this.props;
    if (!canSeeGroup(group)) {
      return null;
    }
    const isAnonymousUser = !currentSiteMember;
    const membersLabel = t(getMembersKey(group));
    return (
      <Box article className={styles.root} data-hook="members-widget">
        <Text
          className={classname(discussionStyles.widgetHeader, styles.header)}
        >
          {canInviteMembers(group)
            ? t('groups-web.add.members.title', { membersLabel })
            : membersLabel}
        </Text>

        {isAnonymousUser ? (
          <Text className={styles.description}>
            {t('groups-web.members-widget.log-in-to-view')}
          </Text>
        ) : (
          <MembersList />
        )}

        <div className={styles.ctaButtonWrapper}>
          {isAnonymousUser ? (
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={() => promptLogin()}
            >
              {t('groups-web.members-widget.log-in')}
            </TextButton>
          ) : (
            <MembersWidgetCTAButton />
          )}
        </div>
      </Box>
    );
  }
}

const enhance = compose(translate(), WithGroup, withSiteMembers);

export const MembersWidget = enhance(
  MembersWidgetComponent,
) as React.ComponentType;
