import * as React from 'react';

import { GroupActionsContext } from './GroupActionsContext';
import { GroupContextConsumer } from './GroupContext';
import { ExternalVideoMetadataProps } from '@wix/social-groups-common/dist/src/components/ContentEditor/ExternalVideoMetadata';

export interface WithExternalVideoMetadataProps
  extends ExternalVideoMetadataProps {}

export const withExternalVideoMetadataAction = <
  P extends WithExternalVideoMetadataProps
>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<
  P,
  keyof WithExternalVideoMetadataProps
>> => props => (
  <GroupContextConsumer>
    {({ externalVideosMetadataRegistry }) => (
      <GroupActionsContext.Consumer>
        {({ getExternalVideoMetadata }) => (
          <WrappedComponent
            getExternalVideoMetadata={getExternalVideoMetadata}
            externalVideosMetadataRegistry={externalVideosMetadataRegistry}
            {...(props as P)}
          />
        )}
      </GroupActionsContext.Consumer>
    )}
  </GroupContextConsumer>
);
