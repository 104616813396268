import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { isAdmin, isGroupSecret } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { WithGroup, WithGroupProps } from '../../Context';
import { NewPostModal } from '../../modals/NewPostModal';
import { DeletePostModal } from '../../modals/DeletePostModal';
import { ThreeDots } from '../../../ThreeDots/ThreeDots';
import { RawDraftContentState } from 'wix-rich-content-editor-common';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

interface PostActionsProps {
  itemId: string;
  contentState: RawDraftContentState;

  isPinnedPost: boolean;
  isActivityPost: boolean;
  isFollowedPost: boolean;

  deletePost(): void;

  pinPost(): void;

  unpinPost(): void;

  followPost(): void;

  unfollowPost(): void;

  updatePost(content: RawDraftContentState, attachments?): void;

  postAuthor: ApiTypes.v1.GroupMemberResponse;

  onShare();

  iconClassName?: string;
}

interface PostActionsState {
  isActionsMenuOpened: boolean;
  isDeletePostModalOpened: boolean;
  isShareDialogOpen: boolean;
  inEditMode: boolean;
}

class PostActionsComponent extends React.Component<
  PostActionsProps &
    InjectedTranslateProps &
    WithGroupProps &
    WithBiLoggerProps,
  PostActionsState
> {
  private readonly biParams;
  constructor(props) {
    super(props);
    this.state = {
      isActionsMenuOpened: false,
      isDeletePostModalOpened: false,
      inEditMode: false,
      isShareDialogOpen: false,
    };

    this.biParams = {
      group_id: this.props.group.groupId,
      post_id: this.props.itemId,
      origin: 'three_dots_menu',
      userEntry: BIUserEntry.SITE,
    };
  }

  closeActionsMenu = () => this.setState({ isActionsMenuOpened: false });
  toggleActionsMenu = () =>
    this.setState({ isActionsMenuOpened: !this.state.isActionsMenuOpened });

  openDeletePostPopup = () =>
    this.setState({
      isDeletePostModalOpened: true,
      isActionsMenuOpened: false,
    });
  closeDeletePostModal = () =>
    this.setState({ isDeletePostModalOpened: false });

  openEditMode = () => {
    const { biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: 'edit',
        ...this.biParams,
      });
    });
    this.setState({ inEditMode: true, isActionsMenuOpened: false });
  };

  closeEditMode = () => this.setState({ inEditMode: false });

  handlePinUnpinPost = () => {
    const { pinPost, unpinPost, isPinnedPost, biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: isPinnedPost ? 'unpin' : 'pin',
        ...this.biParams,
      });
    });
    isPinnedPost ? unpinPost() : pinPost();
    this.closeActionsMenu();
  };

  handleDeletePost = () => {
    const { biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: 'delete',
        ...this.biParams,
      });
    });
    this.props.deletePost();
    this.closeDeletePostModal();
  };

  handleUpdatePost = (content: RawDraftContentState, attachments?) => {
    this.props.updatePost(content, attachments);
    this.closeEditMode();
  };

  handleFollowUnfollowPost = () => {
    const { followPost, unfollowPost, isFollowedPost, biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: isFollowedPost ? 'unfollow' : 'follow',
        ...this.biParams,
      });
    });
    isFollowedPost ? unfollowPost() : followPost();
    this.closeActionsMenu();
  };

  handleShareClick = () => {
    const { biLogger, onShare } = this.props;
    tryToCallBi(async () => {
      await biLogger.postActionClick({
        action: 'share',
        ...this.biParams,
      });
    });
    onShare();
  };

  renderMenuItems = () => {
    const {
      t,
      postAuthor,
      currentMember,
      group,
      isPinnedPost,
      isFollowedPost,
      isActivityPost,
    } = this.props;
    const items = [];

    const isMyPost = currentMember.siteMemberId === postAuthor.siteMemberId;
    const canDeletePost = isAdmin(group) || isMyPost;
    const canPinUnpinPost = isAdmin(group);
    const canFollow = true;
    const canEditPost = isMyPost && !isActivityPost;
    const canSharePost = !isGroupSecret(group);

    canPinUnpinPost &&
      items.push({
        onClick: this.handlePinUnpinPost,
        content: t(
          isPinnedPost
            ? 'groups-web.discussion.feed.post-actions.unpin-post'
            : 'groups-web.discussion.feed.post-actions.pin-post',
        ),
      });

    canFollow &&
      items.push({
        onClick: this.handleFollowUnfollowPost,
        content: t(
          isFollowedPost
            ? 'groups-web.discussion.feed.post-actions.following'
            : 'groups-web.discussion.feed.post-actions.follow',
        ),
      });

    canSharePost &&
      items.push({
        onClick: this.handleShareClick,
        content: t('groups-web.discussion.feed.post-actions.share'),
      });

    canEditPost &&
      items.push({
        onClick: this.openEditMode,
        content: t('groups-web.discussion.feed.post-actions.edit'),
      });

    canDeletePost &&
      items.push({
        onClick: this.openDeletePostPopup,
        content: t('groups-web.discussion.feed.post-actions.delete'),
      });

    return items;
  };

  render() {
    const { contentState, iconClassName, t } = this.props;
    const {
      isActionsMenuOpened,
      isDeletePostModalOpened,
      inEditMode,
    } = this.state;
    return (
      <div>
        <ThreeDots
          onClose={this.closeActionsMenu}
          isOpen={isActionsMenuOpened}
          onOpen={this.toggleActionsMenu}
          items={this.renderMenuItems()}
          iconClassName={iconClassName}
        />
        <DeletePostModal
          isOpen={isDeletePostModalOpened}
          onClose={this.closeDeletePostModal}
          onDelete={this.handleDeletePost}
        />
        {inEditMode ? (
          <NewPostModal
            isOpen
            isPostPublishing={false}
            onSubmit={this.handleUpdatePost}
            onVisibilityChange={this.closeEditMode}
            initialContentState={contentState}
          />
        ) : null}
      </div>
    );
  }
}

const enhance = compose(withBiLogger, WithGroup, translate());

export const PostActions = enhance(PostActionsComponent) as React.ComponentType<
  PostActionsProps
>;
