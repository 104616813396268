import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Box } from '@wix/social-groups-common/dist/src/components/Box';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';

import { compose } from '@wix/social-groups-common/dist/src/compose';
import { isGroupSecret, isJoined, memberWrapper } from '@wix/social-groups-api';
import {
  ApiTypes,
  ReactionsApiTypes,
} from '@wix/social-groups-api/dist/src/types';

import { RawDraftContentState } from 'wix-rich-content-editor-common';

import { IFeedItem } from '../../../../controllers/types';
import { PostContentViewer } from '../../PostContentEditor/PostContentViewer'; //long imports == better performance
import { SiteMembers } from '../../Context/SiteMembers';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../Context/withAppSettings';

const Reactions = React.lazy(() =>
  import(/* webpackChunkName: "reactions" */ '../Reactions/Reactions').catch(
    e => {
      return { default: () => null };
    },
  ),
);

const ReactedMembers = React.lazy(() =>
  import(
    /* webpackChunkName: "reactedMembers" */ '../Reactions/ReactedMembers/ReactedMembers'
  ).catch(e => {
    return { default: () => null };
  }),
);
const Comments = React.lazy(() =>
  import(/* webpackChunkName: "comments" */ '../Comments/Comments').catch(e => {
    return { default: () => null };
  }),
);
import { AuthorInfo } from '../AuthorInfo';
import { PostActions } from '../PostActions';

import styles from './FeedItem.st.css';
import { ShareButton } from '../../Share';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import {
  MembershipChangeAction,
  WithGroup,
  WithGroupProps,
  withMembershipChangeAction,
  withSiteMembers,
} from '../../Context';
import { WithSiteMembers } from '../../Context/withSiteMembers';
import { getActivityBody } from '../ActivityPost/ActivityBody';

export interface FeedItemProps {
  feedItem: IFeedItem;
  siteMembersMap: SiteMembers['siteMembersMap'];
  postAuthor: ApiTypes.v1.GroupMemberResponse;
  currentMember: ApiTypes.v1.GroupMemberResponse;
  contextToken: string;

  onDeletePostClick(feedItemId: string);

  onPinPostClick(feedItemId: string): void;

  onUnpinPostClick(feedItemId: string): void;

  onFollowPostClick(feedItemId: string): void;

  onUnfollowPostClick(feedItemId: string): void;

  onUpdatePostClick(feedItemId: string, entity): void;

  react(feedItemId: string, reaction: ReactionsApiTypes.Reaction): void;

  unreact(feedItemId: string, reactionCode: string): void;

  onShare();
}

interface FeedItemComponentState {
  totalComments: number;
  showComments: boolean;
  didMount: boolean;
  contentState: RawDraftContentState;
}

export class FeedItemComponent extends React.Component<
  FeedItemProps &
    InjectedTranslateProps &
    WithTpaComponentsConfigProps &
    WithAppSettingsProps &
    WithSiteMembers &
    WithGroupProps &
    MembershipChangeAction,
  FeedItemComponentState
> {
  state: FeedItemComponentState = {
    didMount: false,
    totalComments: 0,
    showComments: true,
    contentState: null,
  };

  async componentDidMount(): Promise<void> {
    const { entity, activity } = this.props.feedItem;
    let contentState = null;

    if (entity) {
      contentState = JSON.parse(entity.body.content);
    }

    if (activity) {
      contentState = await getActivityBody(activity);
    }

    this.setState({ didMount: true, contentState });
  }

  handleReact = (reaction: ReactionsApiTypes.Reaction) => {
    this.handleProtectedActionClick(() => {
      this.props.react(this.props.feedItem.feedItemId, reaction);
    });
  };

  handleUnreact = (reactionCode: string) => {
    this.handleProtectedActionClick(() => {
      this.props.unreact(this.props.feedItem.feedItemId, reactionCode);
    });
  };

  handleProtectedActionClick = (protectedAction: Function) => {
    // !!! Can not use currentMember here, because it is not updated after changing membership
    const { group, isLoggedIn, promptLogin, openJoinDialog } = this.props;
    if (!isLoggedIn) {
      promptLogin();
      return;
    }

    if (!isJoined(group)) {
      openJoinDialog();
      return;
    }

    protectedAction();
  };

  toggleComments = () =>
    this.setState({ showComments: !this.state.showComments });

  render() {
    const {
      appSettings,
      feedItem,
      t,
      onDeletePostClick,
      onUpdatePostClick,
      onPinPostClick,
      onUnpinPostClick,
      onFollowPostClick,
      onUnfollowPostClick,
      onShare,
      mobile,
      contextToken,
      wixCodeApiParams,
      group,
    } = this.props;

    const { showComments, contentState, didMount } = this.state;

    const { feedItemId, activity } = feedItem;

    const postAuthor = this.getPostAuthor();
    const { name, imageUrl, relationship, roles } = postAuthor;

    const showShareButton =
      appSettings.discussionsDisplay.showShareButton && !isGroupSecret(group);

    const renderComments = showComments && didMount && wixCodeApiParams;
    return (
      <Box
        {...styles('root', {
          mobile,
          withReactions: feedItem.reactions.total > 0,
        })}
        data-hook="feed-item"
      >
        <div className={styles.meta}>
          <AuthorInfo
            name={name.nick || t('groups-web.member.anonymous')}
            avatarUrl={imageUrl}
            activity={activity}
            timeStamp={feedItem.createdAt}
            relationship={relationship}
            roles={roles}
          />
          {showShareButton && (
            <ShareButton {...styles('shareButton')} onClick={onShare} />
          )}
          <PostActions
            itemId={feedItemId}
            isActivityPost={!!activity}
            isPinnedPost={!!feedItem.pin}
            isFollowedPost={
              feedItem.requesterContext && feedItem.requesterContext.subscribed
            }
            postAuthor={postAuthor}
            deletePost={() => onDeletePostClick(feedItemId)}
            updatePost={entity => onUpdatePostClick(feedItemId, entity)}
            pinPost={() => {
              onPinPostClick(feedItemId);
            }}
            unpinPost={() => {
              onUnpinPostClick(feedItemId);
            }}
            followPost={() => {
              this.handleProtectedActionClick(() => {
                onFollowPostClick(feedItemId);
              });
            }}
            unfollowPost={() => {
              this.handleProtectedActionClick(() => {
                onUnfollowPostClick(feedItemId);
              });
            }}
            contentState={contentState}
            onShare={onShare}
            iconClassName={styles.threeDotsIcon}
          />
        </div>
        {contentState ? (
          <div className={styles.viewer}>
            <PostContentViewer contentState={contentState} />
          </div>
        ) : null}
        {this.renderReactions()}
        {renderComments /* TODO: Comments are not ready for SSR */ ? (
          <React.Suspense fallback={<Spinner />}>
            <Comments
              enableShare={!isGroupSecret(group)}
              memberAuth={this.handleProtectedActionClick}
              token={contextToken}
              entityID={feedItem.feedItemId}
              updateTotal={this.updateTotalComments}
            />
          </React.Suspense>
        ) : null}
      </Box>
    );
  }

  private renderReactions() {
    const { didMount } = this.state;
    if (!didMount) {
      return null;
    }
    const { feedItem, t, siteMembersMap, appSettings } = this.props;

    const { showReactions } = appSettings.discussionsDisplay;

    const hasReactions = !!feedItem.reactions.usersReacted.total;
    return (
      <React.Suspense fallback={<Spinner />}>
        <div className={styles.reactionsAndMeta}>
          {showReactions && (
            <div className={styles.reactions}>
              <Reactions
                siteMembersMap={siteMembersMap}
                reactions={feedItem.reactions}
                react={this.handleReact}
                unreact={this.handleUnreact}
              />

              {!hasReactions && (
                <span onClick={this.toggleComments}>
                  <Text
                    {...styles('totalComments')}
                    typography={TYPOGRAPHY.listText}
                  >
                    {t('groups-web.discussion.feed.total-comments', {
                      count: this.state.totalComments,
                    })}
                  </Text>
                </span>
              )}
            </div>
          )}

          {(hasReactions || !showReactions) && (
            <div className={styles.meta}>
              <ReactedMembers
                siteMembersMap={siteMembersMap}
                reactions={feedItem.reactions}
              />
              <span onClick={this.toggleComments}>
                <Text
                  {...styles('totalComments')}
                  typography={TYPOGRAPHY.listText}
                >
                  {t('groups-web.discussion.feed.total-comments', {
                    count: this.state.totalComments,
                  })}
                </Text>
              </span>
            </div>
          )}
        </div>
      </React.Suspense>
    );
  }

  private readonly updateTotalComments = (totalComments: number) => {
    this.setState({ totalComments });
  };

  private readonly getPostAuthor = (): ApiTypes.v1.GroupMemberResponse => {
    let postAuthor = this.props.postAuthor;
    const { feedItem, siteMembersMap } = this.props;
    if (feedItem.activity && feedItem.activity.data.authorUserId) {
      postAuthor = siteMembersMap[feedItem.activity.data.authorUserId];
    }
    return memberWrapper(postAuthor);
  };
}

const enhance = compose(
  translate(),
  withTpaComponentsConfig,
  withAppSettings,
  WithGroup,
  withSiteMembers,
  withMembershipChangeAction,
);

export const FeedItem = enhance(FeedItemComponent) as React.ComponentType<
  FeedItemProps
>;

FeedItem.displayName = 'FeedItem';
