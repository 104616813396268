import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import { RoleBadges } from '@wix/social-groups-common/dist/src/components/RoleBadges';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import styles from './Details.st.css';
import {
  WithGroup,
  WithGroupProps,
  withAppSettings,
  WithAppSettingsProps,
} from '../Context';
import { MembersCount } from '../MembersCount';

export const DETAILS = 'DETAILS';
export const DETAILS_TITLE = `${DETAILS}_TITLE`;

// TODO: extract shared translations
export const DetailsComponent: React.FC<WithGroupProps &
  InjectedTranslateProps &
  WithAppSettingsProps> = ({ group, t, appSettings }) => {
  const {
    details,
    relationship,
    roles,
    settings: { privacyLevel },
  } = group;

  const { showMembersCount } = appSettings.discussionsDisplay;

  return (
    <div data-hook={DETAILS}>
      <div className={styles.titleAndRole}>
        <Text data-hook={DETAILS_TITLE} {...styles(styles.title)}>
          {details.title}
        </Text>
        <div className={styles.badgesSection}>
          <RoleBadges relationship={relationship} roles={roles} />
        </div>
      </div>
      <div className={styles.privacyAndMembers}>
        <Text {...styles(styles.groupInfoItem)}>
          {t(`groups-web.types.${privacyLevel.toLowerCase()}.name`)}
        </Text>
        {showMembersCount && (
          <>
            <Text {...styles(styles.groupInfoItem)}>&nbsp;·&nbsp;</Text>
            <MembersCount {...styles(styles.groupInfoItem)} />
          </>
        )}
      </div>
    </div>
  );
};
const enhance = compose(translate(), WithGroup, withAppSettings);

export const Details = enhance(DetailsComponent);
