import { ReactionsApiTypes } from '@wix/social-groups-api/dist/src/types';

import { observable, action, flow } from 'mobx';
import { serializable, object, list, primitive } from 'serializr';

import {
  IUserReaction,
  IUsersReactions,
  IUsersReacted,
  IReaction,
  IReactions,
} from './types';

class Reaction implements IReaction {
  @serializable
  reactionCode: string;

  constructor(reaction: ReactionsApiTypes.Reaction) {
    this.reactionCode = reaction.reactionCode;
  }
}

class UserReaction implements IUserReaction {
  @serializable
  userId: string;

  @serializable(object(Reaction))
  reaction: Reaction;

  @serializable
  reactionTime: Date;

  constructor(userReaction: ReactionsApiTypes.UserReaction) {
    this.userId = userReaction.userId;
    this.reactionTime = userReaction.reactionTime;
    this.reaction = new Reaction(userReaction.reaction);
  }
}

class UsersReactions implements IUsersReactions {
  @serializable
  @observable
  total: number;

  @serializable(list(object(UserReaction)))
  @observable.shallow
  reactions: UserReaction[];

  constructor(usersReactions: ReactionsApiTypes.UsersReactions) {
    this.total = usersReactions.total;
    this.reactions = usersReactions.userReactions.map(
      userReaction => new UserReaction(userReaction),
    );
  }
}

class UsersReacted implements IUsersReacted {
  @serializable
  @observable
  total: number;

  @serializable(list(primitive()))
  @observable.shallow
  userIds: string[];

  constructor(usersReacted: ReactionsApiTypes.Users) {
    this.total = usersReacted.total;
    this.userIds = usersReacted.userIds;
  }
}

export class Reactions implements IReactions {
  @serializable
  total: number;

  @serializable(object(UsersReactions))
  @observable
  usersReactions: UsersReactions;

  @serializable(object(UsersReacted))
  @observable
  usersReacted: UsersReacted;

  constructor(
    reactionsSummary: ReactionsApiTypes.ItemReactionsSummary = {
      total: 0,
      usersReactions: {
        total: 0,
        userReactions: [],
      },
      usersReacted: {
        total: 0,
        userIds: [],
      },
    },
  ) {
    this.total = reactionsSummary.total || 0;
    this.usersReactions = new UsersReactions(reactionsSummary.usersReactions);
    this.usersReacted = new UsersReacted(reactionsSummary.usersReacted);
  }
}
