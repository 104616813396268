import * as React from 'react';
import { PluginsBuilder } from '@wix/social-groups-common/dist/src/components/ContentEditor';
import { ContentEditor } from '../../ContentEditor/ContentEditor';
import {
  FileUploaderProps,
  withFileUploader,
} from '@wix/social-groups-common/dist/src/components/ContentEditor/withFileUploader';
import { withUploadAction, WithUploadProps } from '../Context';
import {
  Block,
  BlockAlign,
  Container,
  Loader,
  Button,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { countChars } from '@wix/social-groups-common/dist/src/components/ContentEditor/utils';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { EditorState } from 'wix-rich-content-editor-common';

import styles from './AboutEditor.st.css';
import { withExternalVideoMetadataAction } from '../Context/ExternalVideoMetadataAction';
import {
  ExternalVideoUploaderProps,
  withExternalVideoUploader,
} from '@wix/social-groups-common/dist/src/components/ContentEditor/withExternalVideoUploader';
import { ModalParentNodeRefProvider } from '@wix/social-groups-common/dist/src/components/Modal/ModalParentNodeRefContext';

interface AboutEditorProps {
  editorState: EditorState;
  loading: boolean;

  saveChanges(editorState: EditorState);

  cancel();
}

const plugins = PluginsBuilder.media().build();

interface AboutEditorState {
  contentEditorState: EditorState;
}

type Props = AboutEditorProps &
  FileUploaderProps &
  WithUploadProps &
  ExternalVideoUploaderProps &
  InjectedTranslateProps;

export class AboutEditorComponent extends React.Component<
  Props,
  AboutEditorState
> {
  $parent = React.createRef<HTMLDivElement>();
  private readonly helpers;
  private readonly config;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      contentEditorState: props.editorState,
    };
    this.helpers = {
      onFilesChange: props.onFilesChange,
      onVideoSelected: props.onVideoSelected,
    };
    this.config = {
      ...props.fileUploadConfig,
    };
  }

  onChange = contentEditorState => {
    this.setState({ contentEditorState });
  };
  onSave = () => {
    this.props.saveChanges(this.state.contentEditorState);
  };

  render() {
    const { t, loading, cancel } = this.props;
    const { contentEditorState } = this.state;
    const enoughText = countChars(contentEditorState.getCurrentContent()) >= 20;
    return (
      <Container>
        <div {...styles(styles.root)} ref={this.$parent}>
          <ModalParentNodeRefProvider value={this.$parent.current}>
            <ContentEditor
              config={this.config}
              helpers={this.helpers}
              onChange={this.onChange}
              editorState={contentEditorState}
              plugins={plugins}
            />
          </ModalParentNodeRefProvider>
        </div>
        <Block place={BlockAlign.end}>
          <Button priority={PRIORITY.secondary} onClick={cancel}>
            {t('groups-web.cancel')}
          </Button>
          <Tooltip
            content={t('groups-web.about.edit.tip')}
            placement="top"
            disabled={enoughText}
          >
            <Button
              priority={PRIORITY.primary}
              onClick={this.onSave}
              disabled={loading || !enoughText}
              prefixIcon={loading && <Loader />}
            >
              {t('groups-web.save')}
            </Button>
          </Tooltip>
        </Block>
      </Container>
    );
  }
}

const enhance = compose(
  translate(),
  withUploadAction,
  withFileUploader,
  withExternalVideoMetadataAction,
  withExternalVideoUploader,
);
export const AboutEditor: React.ComponentType<AboutEditorProps> = enhance(
  AboutEditorComponent,
);
