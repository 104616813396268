import { SocialContentService } from '@wix/social-groups-api';
import {
  CommentsApiTypes,
  ReactionsApiTypes,
  FeedApiTypes,
} from '@wix/social-groups-api/dist/src/types';

import { observable, action, flow } from 'mobx';
import { serializable, object, list, primitive } from 'serializr';

import {
  SocialText,
  IFeedItemEntity,
  IFeedItem,
  IFeedItemComments,
  IFeedItemPin,
  IFeedItemRequester,
  IFeedItemActivity,
  FeedItemActivityType,
  IFeedItemActivityData,
} from './types';
import { Comment } from './Comment';
import { Attachment } from './Attachment';
import { Reactions } from './Reactions';

class FeedItemActivityData implements IFeedItemActivityData {
  @serializable
  hasCoverBefore: boolean;

  @serializable
  src: string;

  @serializable
  content: string;

  @serializable(list(primitive()))
  userIds: string[] = [];

  @serializable
  authorUserId: string;
}

class FeedItemActivity implements IFeedItemActivity {
  @serializable
  activityType: FeedItemActivityType;

  @serializable(object(FeedItemActivityData))
  data: FeedItemActivityData;
}

class FeedItemEntity implements IFeedItemEntity {
  @serializable(object(SocialText))
  body: SocialText;
}

class FeedItemPin implements IFeedItemPin {
  @serializable
  since: Date;

  @serializable
  pinnedBy: string;
}

class FeedItemRequester implements IFeedItemRequester {
  @serializable
  subscribed: boolean;
}

class FeedItemComments implements IFeedItemComments {
  @serializable(list(object(Comment)))
  @observable.shallow
  comments: Comment[] = [];

  @observable
  @serializable
  total: number = 0;

  @serializable
  contextToken: string;

  constructor(comments: FeedApiTypes.Comments) {
    this.total = comments.total;
    this.comments = comments.comments.map(comment => new Comment(comment));
    this.contextToken = comments.contextToken;
  }

  add(comment: CommentsApiTypes.Comment) {
    this.comments.push(new Comment(comment));
    this.total++;
  }
}

export class FeedItem implements IFeedItem {
  @serializable
  feedItemId: string;

  @serializable
  createdAt: Date;

  @serializable
  createdBy: string;

  @serializable(object(FeedItemEntity))
  entity: FeedItemEntity;

  @serializable(object(FeedItemActivity))
  activity: FeedItemActivity;

  @serializable(object(FeedItemComments))
  comments: FeedItemComments;

  @serializable(object(Reactions))
  @observable
  reactions: Reactions;

  @serializable(list(object(Attachment)))
  @observable.shallow
  attachments: Attachment[] = [];

  @serializable(object(FeedItemPin))
  @observable
  pin: FeedItemPin;

  @serializable(object(FeedItemRequester))
  @observable
  requesterContext: FeedItemRequester;

  constructor(
    feedItem: FeedApiTypes.FeedItem,
    private readonly repository: SocialContentService,
  ) {
    this.feedItemId = feedItem.feedItemId;
    this.createdAt = feedItem.createdAt;
    this.createdBy = feedItem.createdBy;

    this.entity = feedItem.entity as FeedItemEntity;
    this.activity = (feedItem as any).activity as FeedItemActivity;

    this.comments = new FeedItemComments(feedItem.comments);
    this.reactions = new Reactions(feedItem.reactions);
    this.attachments = (
      (feedItem.entity && feedItem.entity.attachments) ||
      []
    ).map(attachment => new Attachment(attachment));
    this.pin = feedItem.pin as FeedItemPin;
    this.requesterContext = feedItem.requesterContext as FeedItemRequester;
  }

  @action
  react = flow(function*(this: FeedItem, reaction: ReactionsApiTypes.Reaction) {
    try {
      const response: {
        data: ReactionsApiTypes.ItemReactionsSummary;
      } = yield this.repository.reactions.react({
        itemId: this.feedItemId,
        reaction,
      });

      this.reactions = new Reactions(response.data);
    } catch (error) {
      console.error('FeedItem react error: ', error);
    }
  });

  @action
  unreact = flow(function*(this: FeedItem, reactionCode: string) {
    try {
      const response: {
        data: ReactionsApiTypes.ItemReactionsSummary;
      } = yield this.repository.reactions.unreact({
        itemId: this.feedItemId,
        reactionCode,
      });

      this.reactions = new Reactions(response.data);
    } catch (error) {
      console.error('FeedItem unreact error: ', error);
    }
  });

  @action
  comment = flow(function*(
    this: FeedItem,
    comment: CommentsApiTypes.CommentEntity,
  ) {
    try {
      const response: {
        data: CommentsApiTypes.CreateCommentResponse;
      } = yield this.repository.comments.create({
        entityId: this.feedItemId,
        comment,
      });

      this.comments.add(response.data.comment);
    } catch (error) {
      console.error('FeedItem comment error: ', error);
    }
  });
}
