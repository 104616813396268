import * as React from 'react';

import { GroupRule } from '@wix/social-groups-api';
import { ApiTypes, GroupAppsMap } from '@wix/social-groups-api/dist/src/types';
import { FeedControllerProps } from '../../../controllers/feed';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import { UpdateContext } from './GroupUpdate';
import { UploadContext } from '@wix/social-groups-common/dist/src/components/ContentEditor/UploadFile';
import { ExternalVideoMetadataContext } from '@wix/social-groups-common/dist/src/components/ContentEditor/ExternalVideoMetadata';

export interface FeedContext {
  feed: FeedControllerProps;
}

export interface CommentsContext {
  wixCodeApiParams: any;
  setWixCodeApiParams(): void;
}

export interface WixUserContext {
  promptLogin();
  isLoggedIn: boolean;
}

export interface GroupAppsContext {
  apps: GroupAppsMap;
}

export interface GroupContextValue
  extends UploadContext,
    ExternalVideoMetadataContext,
    FeedContext,
    UpdateContext,
    WixUserContext,
    GroupAppsContext,
    CommentsContext {
  group: ApiTypes.v1.GroupResponse;
  members: ApiTypes.v1.GroupMemberResponse[];
  rules: GroupRule[];
  currentMember: ApiTypes.v1.GroupMemberResponse;
}

export const GroupContext = React.createContext<GroupContextValue>({
  setWixCodeApiParams(): void {},
  wixCodeApiParams: undefined,
  apps: {} as GroupAppsMap,
  updateProgress: UpdateProgress.STALE,
  isLoggedIn: false,
  promptLogin() {},
  group: null,
  members: [],
  rules: [],
  currentMember: null,
  uploadedRegistry: [],
  externalVideosMetadataRegistry: [],
  feed: {
    feedLoading: false,
    contextToken: null,
    cursor: null,
    fetchMore: null,
    isFeedItemCreating: false,
    feedItems: [],
    deleteFeedItem: null,
    createFeedItem: null,
    updateFeedItem: null,
    commentFeedItem: null,
    pinFeedItem: null,
    unpinFeedItem: null,
    followFeedItem: null,
    unfollowFeedItem: null,
    reactFeedItem: null,
    unreactFeedItem: null,
  },
});
GroupContext.displayName = 'GroupContext';

export const GroupContextProvider = GroupContext.Provider;
export const GroupContextConsumer = GroupContext.Consumer;

export interface WithGroupProps extends GroupContextValue {}

export const WithGroup = <P extends WithGroupProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof WithGroupProps>> => props => (
  <GroupContextConsumer>
    {contextValue => <WrappedComponent {...props} {...(contextValue as any)} />}
  </GroupContextConsumer>
);
