import * as React from 'react';
import moment from 'moment';

import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import {
  getAllowedRoles,
  RoleBadges,
} from '@wix/social-groups-common/dist/src/components/RoleBadges';

import styles from './AuthorInfo.st.css';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { ActivityStatus } from '../ActivityPost';
import { IFeedItemActivity } from '../../../../controllers/feed/store';

interface AuthorInfoProps {
  avatarUrl: string;
  activity?: IFeedItemActivity;
  name: string;
  timeStamp: Date;
  relationship: ApiTypes.v1.RelationshipWithGroup;
  roles: ApiTypes.v1.RoleInGroup[];
}

export const AuthorInfoComponent: React.FC<AuthorInfoProps &
  WithTpaComponentsConfigProps> = ({
  avatarUrl,
  name,
  timeStamp,
  mobile,
  relationship,
  roles,
  activity,
}) => {
  const allowedRoles = getAllowedRoles(roles);
  return (
    <div {...styles('root', { mobile })}>
      <Avatar
        size={mobile ? AvatarSize.medium : AvatarSize.large}
        src={avatarUrl}
        className={styles.avatar}
      />
      <div>
        <Text typography={TYPOGRAPHY.listText} {...styles('name')}>
          {name}&nbsp;
        </Text>
        {allowedRoles.length ? (
          <>
            <RoleBadges relationship={relationship} roles={allowedRoles} />
            <Text typography={TYPOGRAPHY.listText} {...styles('name')}>
              &nbsp;
            </Text>
          </>
        ) : null}
        {activity ? <ActivityStatus activity={activity} /> : null}
        <br />
        <Text {...styles('timestamp')}>{moment(timeStamp).fromNow()}</Text>
      </div>
    </div>
  );
};

export const AuthorInfo = withTpaComponentsConfig(AuthorInfoComponent);
