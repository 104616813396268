import * as React from 'react';
import {
  ChangeableMemberRoleInGroup,
  MembersQueryInitialResponse,
  MembersQueryResponse,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  WixSiteMember,
  WixSiteMemberActions,
} from '@wix/social-groups-common/dist/src/context';
export interface MembersActions {
  setMembers(group: ApiTypes.v1.GroupResponse);
  addMembers(group: ApiTypes.v1.GroupResponse, memberIds: string[]);
  addAllNonGroupMembers(
    group: ApiTypes.v1.GroupResponse,
    excludedSiteMemberIds: string[],
  );
  changeMemberRoleInGroup(
    groupId: string,
    siteMemberId: string,
    role: ChangeableMemberRoleInGroup,
  );
  followMember(siteMemberId: string);
  unfollowMember(siteMemberId: string);
  getFollowingMembers(userId: string);
  openCurrentUserProfile();
  openUserProfile(siteMemberId: string);
  filterMembers(group: ApiTypes.v1.GroupResponse, searchQuery: string);
  getNewMembers(groupId: string, startDate: Date);
  getPendingMembers(groupId: string, limit?: number, cursor?: string);
  approvePendingMembersRequests(groupId: string, siteMemberIds: string[]);
  rejectPendingMembersRequests(groupId: string, siteMemberIds: string[]);
  removeMembersFromGroup(groupId: string, siteMemberIds: string[]);
}

export interface CurrentMember {
  currentMember: ApiTypes.v1.GroupMemberResponse;
}

export interface SiteMembers extends WixSiteMember {
  followingMembers: string[];
  membersQueryResponse: MembersQueryResponse;
  newMembers: number;
  siteMembers: ApiTypes.v1.GroupMemberResponse[];
  siteMembersMap: { [id: string]: ApiTypes.v1.GroupMemberResponse };
  membersUpdate: string[];
  nonGroupMembersCount: number;
}

export interface PendingMembers {
  pendingMembers: ApiTypes.v1.ListPendingMembersResponse;
}

export interface SiteMembersWithActions extends SiteMembers, PendingMembers {
  membersActions: MembersActions & WixSiteMemberActions;
}

export const SiteMembersContext = React.createContext<SiteMembersWithActions>({
  membersUpdate: undefined,
  currentSiteMember: undefined,
  followingMembers: [],
  membersActions: undefined,
  membersQueryResponse: MembersQueryInitialResponse,
  newMembers: undefined,
  pendingMembers: undefined,
  siteMembers: [],
  siteMembersMap: {},
  nonGroupMembersCount: undefined,
});

SiteMembersContext.displayName = 'SiteMembersContext';
