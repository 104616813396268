import * as React from 'react';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import { AddMembersModalWithSelectAll } from './AddMembersModalWithSelectAll';
import {
  AddMembersModal as AddMembersModalWithoutSelectAll,
  AddMembersModalProps,
} from './AddMembersModal';

const AddMembersModalComponent = ({
  experiments,
  ...rest
}: InjectedExperimentsProps & AddMembersModalProps) => {
  return experiments.enabled('specs.groups.SelectAllMembers-live-site') ? (
    <AddMembersModalWithSelectAll {...rest} />
  ) : (
    <AddMembersModalWithoutSelectAll {...rest} />
  );
};

export const AddMembersModal = withExperiments(AddMembersModalComponent);
