import { SocialContentService } from '@wix/social-groups-api';

import {
  CommentsApiTypes,
  FeedApiTypes,
  ReactionsApiTypes,
} from '@wix/social-groups-api/dist/src/types';

import { observable, action, flow } from 'mobx';
import { serializable, object, list } from 'serializr';
import { unionBy } from 'lodash';

import { IFeedStore } from './types';
import { FeedItem } from './FeedItem';

const FETCH_FEED_FIELDSET = 'comments,reactions,requesterContext';

export class FeedStore implements IFeedStore {
  @serializable(list(object(FeedItem)))
  @observable.shallow
  feedItems: FeedItem[] = [];

  @serializable
  @observable
  loading: boolean = false;

  @serializable
  @observable
  cursor: string = null;

  repository: SocialContentService;

  constructor(repository: SocialContentService) {
    this.repository = repository;
  }

  @action
  fetch = flow(function*(this: FeedStore) {
    this.loading = true;

    try {
      const response = yield this.repository.feed.list({
        fieldset: FETCH_FEED_FIELDSET,
        query: {
          limit: 10,
        },
      });

      this.addFeedItems(
        response.data.feedItems.map(
          feedItem => new FeedItem(feedItem, this.repository),
        ),
      );

      this.cursor = response.data.nextPageCursor;
    } catch (error) {
      console.error('FeedStore FeedItems fetch error: ', error);
    } finally {
      this.loading = false;
    }
  });

  @action
  fetchMore = flow(function*(this: FeedStore) {
    if (!this.cursor) {
      return;
    }

    try {
      const response = yield this.repository.feed.list({
        fieldset: FETCH_FEED_FIELDSET,
        cursor: {
          cursor: this.cursor,
          limit: 10,
        },
      });

      this.addFeedItems(
        response.data.feedItems.map(
          feedItem => new FeedItem(feedItem, this.repository),
        ),
      );

      this.cursor = response.data.nextPageCursor || null;
    } catch (error) {
      console.error('FeedStore FeedItems fetch error: ', error);
    }
  });

  @action.bound
  fetchById = flow(function*(feedItemId: string) {
    try {
      const exists = this.feedItems.some(
        feedItem => feedItem.feedItemId === feedItemId,
      );
      if (exists) {
        return;
      }

      const response = yield this.repository.feed.get({
        fieldset: FETCH_FEED_FIELDSET,
        feedItemId,
      });

      this.addFeedItems([new FeedItem(response.data, this.repository)]);
    } catch (error) {
      console.error(
        `FeedStore FeedItems fetch item by id = "${feedItemId} error: `,
        error,
      );
    }
  });

  @action
  create = flow(function*(
    this: FeedStore,
    entity: FeedApiTypes.FeedItemEntity,
  ) {
    try {
      const response = yield this.repository.feed.create({
        entity,
        fieldset: 'comments,requesterContext',
      });

      this.feedItems.unshift(new FeedItem(response.data, this.repository));
    } catch (error) {
      console.error('FeedStore FeedItem create error: ', error);
    }
  });

  @action
  update = flow(function*(
    this: FeedStore,
    feedItemId: string,
    entity: FeedApiTypes.FeedItemEntity,
  ) {
    try {
      yield this.repository.feed.update({
        feedItemId,
        entity,
        updateMask: {
          paths: [
            'entity.body',
            'entity.mentions',
            'entity.attachments',
            'entity.externalReferences',
          ],
        },
      });
      this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem update error: ', error);
    }
  });

  @action
  delete = flow(function*(this: FeedStore, feedItemId: string) {
    try {
      const index = this.feedItems.findIndex(
        feedItem => feedItem.feedItemId === feedItemId,
      );
      if (index !== -1) {
        yield this.repository.feed.delete(feedItemId);
        this.feedItems.splice(index, 1);
      }
    } catch (error) {
      console.error('FeedStore FeedItem delete error: ', error);
    }
  });

  @action
  pin = flow(function*(this: FeedStore, feedItemId: string) {
    try {
      const pinnedFeedItem = this.feedItems.find(feedItem => feedItem.pin);
      if (pinnedFeedItem) {
        yield this.repository.feed.unpinFromContainer(
          pinnedFeedItem.feedItemId,
        );
      }
      yield this.repository.feed.pinToContainer(feedItemId);
      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem pin error: ', error);
    }
  });

  @action
  unpin = flow(function*(this: FeedStore, feedItemId: string) {
    try {
      yield this.repository.feed.unpinFromContainer(feedItemId);
      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem unpin error: ', error);
    }
  });

  @action
  follow = flow(function*(this: FeedStore, feedItemId: string) {
    try {
      yield this.repository.feed.subscribeToFeedItem(feedItemId);
      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem follow error: ', error);
    }
  });

  @action
  unfollow = flow(function*(this: FeedStore, feedItemId: string) {
    try {
      yield this.repository.feed.unsubscribeFromFeedItem(feedItemId);
      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem unfollow error: ', error);
    }
  });

  @action
  react = flow(function*(
    this: FeedStore,
    feedItemId: string,
    reaction: ReactionsApiTypes.Reaction,
  ) {
    return this.feedItems
      .find(feedItem => feedItem.feedItemId === feedItemId)
      .react(reaction);
  });

  @action
  unreact = flow(function*(
    this: FeedStore,
    feedItemId: string,
    reactionCode: string,
  ) {
    return this.feedItems
      .find(feedItem => feedItem.feedItemId === feedItemId)
      .unreact(reactionCode);
  });

  comment = flow(function*(
    this: FeedStore,
    feedItemId: string,
    comment: CommentsApiTypes.CommentEntity,
  ) {
    return this.feedItems
      .find(feedItem => feedItem.feedItemId === feedItemId)
      .comment(comment);
  });

  private sortFeedItems() {
    this.feedItems = this.feedItems
      .slice(0, this.feedItems.length)
      .sort((f1, f2) => {
        // need to sort, because we need to add post fetch by Id into correct position
        return (
          new Date(f2.createdAt).getTime() - new Date(f1.createdAt).getTime()
        );
      });
  }

  private addFeedItems(arr: FeedItem[]) {
    const merged = unionBy(arr, this.feedItems, 'feedItemId');
    this.feedItems = merged;
    this.sortFeedItems();
  }
}
