import * as React from 'react';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import styles from './ActivityStatus.st.css';
import {
  FeedItemActivityType,
  IFeedItemActivity,
} from '../../../../controllers/feed/store';

import { UsersJoined } from './UsersJoined';

interface ActivityStatusProps {
  activity: IFeedItemActivity;
}

interface ActivityStatusComponentProps
  extends ActivityStatusProps,
    InjectedTranslateProps {}

class ActivityStatusComponent extends React.Component<
  ActivityStatusComponentProps
> {
  private readonly statusRenderMap: {
    [key in FeedItemActivityType]: () => React.ReactNode;
  };

  constructor(props) {
    super(props);
    this.statusRenderMap = {
      [FeedItemActivityType.GROUP_COVER_CHANGED]: () =>
        this.renderGroupCoverChanged(),
      [FeedItemActivityType.ABOUT_GROUP_CHANGED]: () =>
        this.renderGroupAboutChanged(),
      [FeedItemActivityType.USERS_JOINED]: () => this.renderUsersJoined(),
    };
  }

  render() {
    return this.statusRenderMap[this.props.activity.activityType]();
  }

  renderGroupCoverChanged() {
    const { activity, t } = this.props;
    const { hasCoverBefore } = activity.data;

    const key = hasCoverBefore
      ? 'groups-web.activity-post.cover-image.updated'
      : 'groups-web.activity-post.cover-image.added';
    return <Text {...styles('root', {})}>{t(key)}</Text>;
  }

  renderGroupAboutChanged() {
    const { t } = this.props;
    return (
      <Text {...styles('root', {})}>
        {t('groups-web.activity-post.about.changed')}
      </Text>
    );
  }

  renderUsersJoined() {
    return <UsersJoined {...this.props.activity.data} />;
  }
}

const enhance = compose(translate());

export const ActivityStatus = enhance(
  ActivityStatusComponent,
) as React.ComponentType<ActivityStatusProps>;
