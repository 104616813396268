import { CommentsApiTypes } from '@wix/social-groups-api/dist/src/types';
import { object, serializable } from 'serializr';

import { IComment, ICommentEntity, SocialIdentity, SocialText } from './types';

class CommentEntity implements ICommentEntity {
  @serializable(object(SocialText)) text: SocialText;
}

export class Comment implements IComment {
  @serializable commentId: string;

  @serializable(object(SocialIdentity)) createdBy: SocialIdentity;

  @serializable createdAt: Date;

  @serializable(object(CommentEntity)) comment: CommentEntity;

  constructor(comment: CommentsApiTypes.Comment) {
    this.commentId = comment.commentId;
    this.createdAt = comment.createdAt;

    this.createdBy = comment.createdBy as SocialIdentity;
    this.comment = comment.comment as ICommentEntity;
  }
}
