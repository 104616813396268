import * as React from 'react';

import { memberWrapper } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { UserCard } from '@wix/social-groups-common';
import { AvatarSize } from 'wix-ui-tpa/Avatar';

import styles from './Media.st.css';

export interface ThumbnailProps {
  description: string;
}

export const Thumbnail: React.FC<ThumbnailProps> = props => {
  let description;
  try {
    description = JSON.parse(
      props.description,
    ) as ApiTypes.v1.GroupMemberResponse;
  } catch (e) {}
  const { name, imageUrl } = memberWrapper(description);
  return (
    <div {...styles(styles.description)}>
      <UserCard name={name.nick} pic={imageUrl} avatarSize={AvatarSize.small} />
    </div>
  );
};
