import * as React from 'react';

import { ContentViewer } from '@wix/social-groups-common/dist/src/components/ContentEditor/ContentViewer';

import { pluginsConfig } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/pluginsConfig';
import {
  allTypeMappers,
  TypeMappersBuilder,
} from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/typeMappers';

import { theme } from '@wix/social-groups-common/dist/src/components/ContentEditor/theme';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';

import { RawDraftContentState } from 'wix-rich-content-editor-common';

import { withAppData, WithAppDataProps } from '../../Group/Context';
import { SiteMembers, MembersActions, withSiteMembers } from '../Context';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { MentionedMember } from './MentionedMember';
import { MENTION_TYPE } from 'wix-rich-content-plugin-mentions/dist/module.viewer.cjs';
import { GALLERY_TYPE } from 'wix-rich-content-plugin-gallery/dist/module.viewer.cjs';
import { POLL_TYPE } from 'wix-rich-content-plugin-social-polls/dist/module.viewer.cjs';

interface PostContentViewerProps {
  contentState: RawDraftContentState;
}

type ComponentProps = PostContentViewerProps &
  SiteMembers &
  MembersActions &
  InjectedExperimentsProps &
  WithAppDataProps;

const typeMappers = allTypeMappers();

const experimentTypeMappers = TypeMappersBuilder.mediaMappers()
  .withMentions()
  .withPoll()
  .build();

class PostContentViewerComponent extends React.Component<ComponentProps> {
  config = {
    ...pluginsConfig,
    [MENTION_TYPE]: {
      onMentionClick: (mention: MentionedMember) =>
        this.props.openUserProfile(mention.id),
      getMentionLink: () => {},
    },
    [POLL_TYPE]: {
      siteToken: this.props.instance,
      getSiteMembers: () => this.props.siteMembers,
    },
  };

  render() {
    const { contentState, experiments } = this.props;
    let typeMappersToUse;

    if (experiments.enabled('specs.groups.EnableSocialPollsPlugin')) {
      typeMappersToUse = experimentTypeMappers;
    } else {
      typeMappersToUse = typeMappers;
    }

    // TODO investigate why gallery broke for galleryLayout === 3
    for (const entityId in contentState.entityMap) {
      const { type, data } = contentState.entityMap[entityId];

      if (type !== GALLERY_TYPE) {
        continue;
      }

      contentState.entityMap[entityId].data.styles = {
        ...data.styles,
        galleryLayout: 2,
      };
    }

    return (
      <ContentViewer
        enableFullscreen
        theme={theme}
        config={this.config}
        typeMappers={typeMappersToUse}
        initialState={contentState}
      />
    );
  }
}

const enhance = compose(withSiteMembers, withAppData, withExperiments);

export const PostContentViewer = enhance(PostContentViewerComponent);
