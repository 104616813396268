import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text } from 'wix-ui-tpa/Text';
import {
  Container,
  PendingMembers,
  RoleBadges,
  withTPAConfig,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  Anonymous,
  isGroupMember,
  isAdmin,
  hasAdminRole,
  hasSiteAdminRole,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  withAppSettings,
  WithAppSettingsProps,
  WithGroup,
  WithGroupProps,
  withSiteMembers,
} from '../Context';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import styles from './Members.st.css';
import { WithSiteMembers } from '../Context/withSiteMembers';
import { EmptyState } from '../EmptyState';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { ThreeDots, ThreeDotsActions } from '../../ThreeDots/ThreeDots';
import { RemoveMemberDialog } from '../Dialogs/RemoveMemberDialog';
import { AddOrRemoveAdminRoleDialog } from '../Dialogs/AddOrRemoveAdminRole';
import { FollowButton } from '../FollowButton';

export interface MembersComponentProps {
  className?: string;
  appSettings?: WithAppSettingsProps['appSettings'];
  includeDeleted?: boolean;
  withMoreActions?: boolean;
}

type InjectedProps = InjectedTranslateProps &
  Pick<WithGroupProps, 'group' | 'members' | 'currentMember' | 'promptLogin'> &
  Partial<WithSiteMembers> &
  TPAComponentsConfig;

const INITIAL_PENDING_MEMBERS_LIMIT = 3;

const enum ActionDialogType {
  REMOVE_MEMBER_FROM_GROUP = 'REMOVE_MEMBER_FROM_GROUP',
  ADD_ADMIN_ROLE = 'ADD_ADMIN_ROLE',
  REMOVE_ADMIN_ROLE = 'REMOVE_ADMIN_ROLE',
}

interface MembersState {
  actionDialogOpenForMemberId: string | null;
  actionsMenuOpenForMemberId: string | null;
  actionDialogType: ActionDialogType | null;
}

export class MembersComponent extends React.Component<
  MembersComponentProps & InjectedProps & WithBiLoggerProps,
  MembersState
> {
  static defaultProps = {
    appSettings: {
      membersDisplay: {},
    },
    withMoreActions: false,
  };

  readonly state: MembersState = {
    actionDialogOpenForMemberId: null,
    actionsMenuOpenForMemberId: null,
    actionDialogType: null,
  };

  componentDidMount(): void {
    const {
      getPendingMembers,
      group: { pendingMemberCount, groupId },
    } = this.props;
    if (pendingMemberCount && getPendingMembers) {
      getPendingMembers(groupId, INITIAL_PENDING_MEMBERS_LIMIT);
    }
  }

  render() {
    const {
      group,
      members,
      currentMember,
      currentSiteMember,
      t,
      openCurrentUserProfile,
      mobile,
      includeDeleted,
      ...rest
    } = this.props;
    if (!currentSiteMember) {
      return this.renderMembersForAnonymous();
    }

    const groupMembers = includeDeleted
      ? members
      : members.filter(isGroupMember);

    return (
      <Container {...styles('root', {}, rest)}>
        {this.renderPendingMembers()}
        {this.renderActionModals(members)}
        <ul>{groupMembers.map(this.renderMember)}</ul>
      </Container>
    );
  }

  private renderPendingMembers() {
    const {
      pendingMembers,
      group: { pendingMemberCount },
    } = this.props;
    if (!pendingMemberCount) {
      return null;
    }
    const willLoadPendingMembers = !pendingMembers && !!pendingMemberCount;
    const members = this.getPendingMembersOrAnonymous(willLoadPendingMembers);

    return members && members.length ? (
      <PendingMembers
        pendingMembers={members}
        approveMember={this.approvePendingMember}
        rejectMember={this.declinePendingMember}
        loadMore={this.loadMorePendingMembers}
        total={pendingMemberCount}
        loading={willLoadPendingMembers}
      />
    ) : null;
  }

  private getPendingMembersOrAnonymous(willLoadPendingMembers) {
    const { pendingMembers } = this.props;
    let members;
    if (willLoadPendingMembers) {
      members = new Array(INITIAL_PENDING_MEMBERS_LIMIT).fill(Anonymous);
    } else if (pendingMembers) {
      members = pendingMembers.members;
    }
    return members;
  }

  private readonly renderMembersForAnonymous = () => {
    const { t, promptLogin } = this.props;
    return (
      <EmptyState
        className={styles.emptyState}
        title={t('group-web.members.anonymous-state.title')}
        content={t('group-web.members.anonymous-state.description')}
        button={{
          label: t('group-web.members.anonymous-state.button'),
          onClick: () => promptLogin(),
        }}
      />
    );
  };

  private readonly renderMember = member => {
    const {
      currentMember,
      group,
      mobile,
      openUserProfile,
      withMoreActions,
    } = this.props;

    const {
      imageUrl,
      name: { nick },
      relationship,
      roles,
      siteMemberId,
    } = member;

    const isCurrentUser = currentMember.siteMemberId === siteMemberId;
    const navigateToUserProfile = () => openUserProfile(siteMemberId);
    const isGroupAdmin = isAdmin(group);

    const showMemberActions = withMoreActions && !isCurrentUser && isGroupAdmin;
    const showFollowButton = withMoreActions;

    return (
      <li
        key={siteMemberId}
        className={styles.memberItem}
        onClick={navigateToUserProfile}
      >
        <Avatar
          size={mobile ? AvatarSize.small : AvatarSize.large}
          src={imageUrl}
          name={nick}
        />
        <div className={styles.userInfo}>
          <div className={styles.nickAndRole}>
            <Text className={styles.username}>{nick}</Text>
            <RoleBadges
              roles={roles}
              relationship={relationship}
              className={styles.roleBadge}
            />
          </div>
        </div>
        {!mobile && showFollowButton ? (
          <div className={styles.mainButtons}>
            <FollowButton siteMemberId={siteMemberId} />
          </div>
        ) : null}
        {showMemberActions && this.renderMemberActions(member)}
      </li>
    );
  };

  private renderActionModals(members) {
    const { withMoreActions, t } = this.props;
    const { actionDialogOpenForMemberId, actionDialogType } = this.state;
    const shouldRenderActionModals =
      withMoreActions && !!actionDialogOpenForMemberId;
    if (!shouldRenderActionModals) {
      return null;
    }

    const memberToPerformAction = members.find(
      member => member.siteMemberId === actionDialogOpenForMemberId,
    );
    const memberFullName =
      memberToPerformAction && memberToPerformAction.name
        ? `${memberToPerformAction.name.nick}`
        : '';

    const isAddOrRemoveAdminRoleDialogOpen =
      actionDialogType === ActionDialogType.ADD_ADMIN_ROLE ||
      actionDialogType === ActionDialogType.REMOVE_ADMIN_ROLE;
    return (
      <>
        <RemoveMemberDialog
          isOpen={
            actionDialogType === ActionDialogType.REMOVE_MEMBER_FROM_GROUP &&
            !!actionDialogOpenForMemberId
          }
          memberFullName={memberFullName}
          onConfirmDeletion={this.confirmMemberRemoval}
          onRequestClose={this.closeActionDialog}
        />
        <AddOrRemoveAdminRoleDialog
          isOpen={isAddOrRemoveAdminRoleDialogOpen}
          memberFullName={memberFullName}
          memberImage={
            actionDialogOpenForMemberId && memberToPerformAction
              ? `${memberToPerformAction.imageUrl}`
              : ''
          }
          onConfirmClick={
            actionDialogType === ActionDialogType.ADD_ADMIN_ROLE
              ? this.confirmAddAdminRole
              : this.confirmRemoveAdminRole
          }
          onRequestClose={this.closeActionDialog}
          okLabel={
            actionDialogType === ActionDialogType.ADD_ADMIN_ROLE
              ? t('groups-web.group.actions.add-admin-role.modal.confirm')
              : t('groups-web.group.actions.remove-admin-role.modal.confirm')
          }
          title={
            actionDialogType === ActionDialogType.ADD_ADMIN_ROLE
              ? t('groups-web.group.actions.add-admin-role.modal.title')
              : t('groups-web.group.actions.remove-admin-role.modal.title')
          }
          description={
            actionDialogType === ActionDialogType.ADD_ADMIN_ROLE
              ? t('groups-web.group.actions.add-admin-role.modal.description')
              : t(
                  'groups-web.group.actions.remove-admin-role.modal.description',
                  {
                    memberFullName,
                  },
                )
          }
        />
      </>
    );
  }

  private renderMemberActions(member: ApiTypes.v1.GroupMemberResponse) {
    const { group } = this.props;
    const { actionsMenuOpenForMemberId } = this.state;
    const showAdditionalActions =
      group.relationship === ApiTypes.v1.RelationshipWithGroup.JOINED &&
      group.roles.includes(ApiTypes.v1.RoleInGroup.GROUP_ADMIN);

    return showAdditionalActions ? (
      <div className={styles.membersActions}>
        <ThreeDots
          onClose={this.closeActionsMenu}
          isOpen={actionsMenuOpenForMemberId === member.siteMemberId}
          onOpen={e => {
            e.stopPropagation();

            this.setState({
              actionsMenuOpenForMemberId: member.siteMemberId,
            });
          }}
          items={this.getMemberMenuItems(member)}
          iconClassName={styles.threeDotsIcon}
          className={styles.threeDotsMenu}
        />
      </div>
    ) : null;
  }

  private readonly closeActionsMenu = () => {
    this.setState({ actionsMenuOpenForMemberId: null });
  };

  private readonly closeActionDialog = () => {
    this.setState({
      actionDialogOpenForMemberId: null,
      actionDialogType: null,
    });
  };

  private readonly confirmMemberRemoval = () => {
    const {
      group: { groupId },
      removeMembersFromGroup,
      biLogger,
    } = this.props;
    const { actionDialogOpenForMemberId } = this.state;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionDone({
        group_id: groupId,
        action: 'remove_member',
        site_member_id: actionDialogOpenForMemberId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
      });
    });
    removeMembersFromGroup(groupId, [actionDialogOpenForMemberId]);
    this.closeActionDialog();
  };

  private readonly confirmAddAdminRole = () => {
    const {
      group: { groupId },
      changeMemberRoleInGroup,
      biLogger,
    } = this.props;
    const { actionDialogOpenForMemberId } = this.state;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionDone({
        group_id: groupId,
        action: 'set_admin',
        site_member_id: actionDialogOpenForMemberId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
      });
    });
    changeMemberRoleInGroup(
      groupId,
      actionDialogOpenForMemberId,
      ApiTypes.v1.RoleInGroup.GROUP_ADMIN,
    );
    this.closeActionDialog();
  };

  private readonly confirmRemoveAdminRole = () => {
    const {
      group: { groupId },
      changeMemberRoleInGroup,
      biLogger,
    } = this.props;
    const { actionDialogOpenForMemberId } = this.state;
    tryToCallBi(async () => {
      await biLogger.memberTabAdminActionDone({
        group_id: groupId,
        action: 'remove_admin',
        site_member_id: actionDialogOpenForMemberId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
      });
    });
    changeMemberRoleInGroup(
      groupId,
      actionDialogOpenForMemberId,
      ApiTypes.v1.RoleInGroup.GROUP_MEMBER,
    );
    this.closeActionDialog();
  };

  private getMemberMenuItems(
    member: ApiTypes.v1.GroupMemberResponse,
  ): ThreeDotsActions[] {
    const {
      biLogger,
      group: { groupId },
      t,
    } = this.props;
    const items = [];
    const { siteMemberId, roles } = member;

    const memberIsSiteAdmin = hasSiteAdminRole(roles);
    const memberIsGroupAdmin = hasAdminRole(roles);

    if (!memberIsGroupAdmin) {
      items.push({
        content: t('groups-web.members.more-actions.add-admin-role.menu-item'),
        onClick: e => {
          e.stopPropagation();

          tryToCallBi(async () => {
            await biLogger.memberTabAdminActionClick({
              group_id: groupId,
              action: 'set_admin',
              site_member_id: siteMemberId,
              origin: 'member_tab_member_option_clk',
              userEntry: BIUserEntry.SITE,
            });
          });

          this.closeActionsMenu();
          this.setState({
            actionDialogOpenForMemberId: siteMemberId,
            actionDialogType: ActionDialogType.ADD_ADMIN_ROLE,
          });
        },
      });
    }
    if (memberIsGroupAdmin && !memberIsSiteAdmin) {
      items.push({
        content: t(
          'groups-web.members.more-actions.remove-admin-role.menu-item',
        ),
        onClick: e => {
          e.stopPropagation();

          tryToCallBi(async () => {
            await biLogger.memberTabAdminActionClick({
              group_id: groupId,
              action: 'remove_admin',
              site_member_id: siteMemberId,
              origin: 'member_tab_member_option_clk',
              userEntry: BIUserEntry.SITE,
            });
          });

          this.closeActionsMenu();
          this.setState({
            actionDialogOpenForMemberId: siteMemberId,
            actionDialogType: ActionDialogType.REMOVE_ADMIN_ROLE,
          });
        },
      });
    }

    items.push({
      content: t(
        'groups-web.members.more-actions.remove-member-from-group.menu-item',
      ),
      onClick: e => {
        e.stopPropagation();
        tryToCallBi(async () => {
          await biLogger.memberTabAdminActionClick({
            group_id: groupId,
            action: 'remove_member',
            site_member_id: siteMemberId,
            origin: 'member_tab_member_option_clk',
            userEntry: BIUserEntry.SITE,
          });
        });

        this.closeActionsMenu();
        this.setState({
          actionDialogOpenForMemberId: siteMemberId,
          actionDialogType: ActionDialogType.REMOVE_MEMBER_FROM_GROUP,
        });
      },
    });

    return items;
  }

  private readonly approvePendingMember = (
    member: ApiTypes.v1.PendingMemberResponse,
  ) => {
    try {
      const {
        group: { groupId },
      } = this.props;

      tryToCallBi(async () => {
        await this.props.biLogger.groupsMemberRequestApproveDecline({
          type: 'approve',
          group_id: this.props.group.groupId,
          isAll: false,
          memberId: member.siteMemberId,
          origin: 'user_lvl_btn',
          userEntry: BIUserEntry.SITE,
        } as any);
      });

      this.props.approvePendingMembersRequests(groupId, [member.siteMemberId]);
      //TODO: loading state?
    } catch (e) {
      //TODO: handle with UI
      console.error('Approve Pending Members: FAIL');
    }
  };

  private readonly declinePendingMember = (
    member: ApiTypes.v1.PendingMemberResponse,
  ) => {
    try {
      const {
        group: { groupId },
      } = this.props;

      tryToCallBi(async () => {
        await this.props.biLogger.groupsMemberRequestApproveDecline({
          type: 'decline',
          group_id: this.props.group.groupId,
          isAll: false,
          memberId: member.siteMemberId,
          origin: 'user_lvl_btn',
          userEntry: BIUserEntry.SITE,
        } as any);
      });

      this.props.rejectPendingMembersRequests(groupId, [member.siteMemberId]);
    } catch (e) {
      console.error('Reject Pending Members: FAIL');
    }
  };

  private readonly loadMorePendingMembers = () => {
    try {
      const {
        group: { groupId },
        pendingMembers: { cursorTokens },
        getPendingMembers,
      } = this.props;
      getPendingMembers(groupId, null, cursorTokens.next);
    } catch (e) {
      console.error('Load more pending members: FAIL');
    }
  };
}

const enhance = compose(
  translate(),
  WithGroup,
  withSiteMembers,
  withTPAConfig,
  withAppSettings,
  withBiLogger,
);

export const Members = enhance(MembersComponent) as React.ComponentType<
  MembersComponentProps
>;

export default Members;
