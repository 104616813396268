import {
  StyleParamType,
  IStyleParam,
} from '@wix/tpa-settings/dist/src/contexts/StylesContext';

import {
  ISettingsParam,
  ISettingsParams,
} from '@wix/tpa-settings/dist/src/contexts/SettingsContext';
import { ButtonType } from '@wix/social-groups-common/dist/src/context';

export enum CoverImageLayout {
  thumbnail = 'thumbnail',
  large = 'large',
  blank = 'blank',
}

export enum MediaLayout {
  grid = 'grid',
  masonry = 'masonry',
  collage = 'collage',
}

export type MediaImagesPerRow = 3 | 4 | 5 | 6;

export interface LayoutStyles {
  general: {
    coverImageFullWidth: IStyleParam<StyleParamType.Boolean>;
    coverImageHeight: IStyleParam<StyleParamType.Number>;
    layoutSpacing: IStyleParam<StyleParamType.Number>;
  };
}

export interface DesignStyles {
  header: {
    groupTitle: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    groupSubTitle: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    tabs: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
  };
  infoPanels: {
    about: {
      title: {
        font: IStyleParam<StyleParamType.Font>;
        color: IStyleParam<StyleParamType.Color>;
      };
      subtitle: {
        font: IStyleParam<StyleParamType.Font>;
        color: IStyleParam<StyleParamType.Color>;
      };
    };
    members: {
      title: {
        font: IStyleParam<StyleParamType.Font>;
        color: IStyleParam<StyleParamType.Color>;
      };
      subtitle: {
        font: IStyleParam<StyleParamType.Font>;
        color: IStyleParam<StyleParamType.Color>;
      };
    };
  };
  posts: {
    createNewPost: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    text: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    memberName: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    postingDate: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    commentsReactionsCounter: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    background: {
      color: IStyleParam<StyleParamType.Color>;
    };
    border: {
      color: IStyleParam<StyleParamType.Color>;
      width: IStyleParam<StyleParamType.Number>;
    };
  };
  buttons: {
    text: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
    };
    color: IStyleParam<StyleParamType.Color>;
    radius: IStyleParam<StyleParamType.Number>;
  };
  textButtons: {
    font: IStyleParam<StyleParamType.Font>;
    color: IStyleParam<StyleParamType.Color>;
  };
  overlay: {
    color: IStyleParam<StyleParamType.Color>;
    icon: {
      color: IStyleParam<StyleParamType.Color>;
    };
    groupHeaderIcons: {
      color: IStyleParam<StyleParamType.Color>;
    };
    postIcons: {
      color: IStyleParam<StyleParamType.Color>;
    };
  };
  app: {
    background: {
      color: IStyleParam<StyleParamType.Color>;
    };
  };
}

export type DesignSettings = ISettingsParams<{
  buttonType: ISettingsParam<ButtonType>;
}>;

export type GeneralLayoutSettings = ISettingsParams<{
  coverImageLayout: ISettingsParam<CoverImageLayout>;
  coverImageFullWidth: ISettingsParam<boolean>;
  coverImageHeight: ISettingsParam<number>;
  layoutSpacing: ISettingsParam<number>;
}>;

export type MediaLayoutSettings = ISettingsParams<{
  mediaLayout: ISettingsParam<MediaLayout>;
  imagesPerRow: ISettingsParam<MediaImagesPerRow>;
}>;

export type DiscussionsDisplaySettings = ISettingsParams<{
  showReactions: ISettingsParam<boolean>;
  showMembersCount: ISettingsParam<boolean>;
  showShareButton: ISettingsParam<boolean>;
  showBreadcrumbs: ISettingsParam<boolean>;
}>;

export type MediaDisplaySettings = ISettingsParams<{
  showNameOnHover: ISettingsParam<boolean>;
}>;

export type MembersDisplaySettings = ISettingsParams<{
  showFilters: ISettingsParam<boolean>;
  showContent: ISettingsParam<boolean>;
  showFollowButton: ISettingsParam<boolean>;
}>;

export type AboutDisplaySettings = ISettingsParams<{
  showActivity: ISettingsParam<boolean>;
}>;

export type TextSettings = ISettingsParams<{
  newPostBoxLabel: ISettingsParam<string>;
}>;
