import * as React from 'react';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
export const withSuspense = <T extends {}>(
  WrappedComponent: React.ComponentType<T>,
): React.ComponentType<T> => props => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <WrappedComponent {...props} />
    </React.Suspense>
  );
};
