import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { RawDraftContentState } from 'wix-rich-content-editor-common';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Avatar } from 'wix-ui-tpa/Avatar';

import { Box } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  withAppSettings,
  WithAppSettingsProps,
  WithGroup,
  WithGroupProps,
  withSiteMembers,
} from '../../Context';

import styles from './NewPost.st.css';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import { WithSiteMembers } from '../../Context/withSiteMembers';
import { Anonymous, SiteMember } from '@wix/social-groups-api';
import { PluginsIconBar } from './PluginsIconBar';

export interface NewPostBoxProps {
  draft?: RawDraftContentState;

  onClick(): void;
}

type Props = NewPostBoxProps &
  InjectedTranslateProps &
  WithGroupProps &
  WithAppSettingsProps &
  WithSiteMembers &
  WithTpaComponentsConfigProps;
const NewPostComponent: React.FC<Props> = props => {
  const {
    onClick,
    currentSiteMember,
    t,
    mobile,
    appSettings,
    draft,
    membersUpdate,
  } = props;

  const updatingCurrentMember =
    membersUpdate &&
    currentSiteMember &&
    membersUpdate.includes(currentSiteMember.id);

  return (
    <Box
      onClick={(!updatingCurrentMember || undefined) && onClick}
      data-hook="new-post-box"
      {...styles('root', { mobile, hasDraft: !!draft, updatingCurrentMember })}
    >
      <Avatar src={currentSiteMember.imageUrl} {...styles('avatar')} />

      {draft ? (
        <Text
          typography={TYPOGRAPHY.runningText}
          {...styles(styles.placeholder, {})}
        >
          {getDraftText()}
        </Text>
      ) : (
        <>
          <Text
            typography={TYPOGRAPHY.runningText}
            {...styles(styles.placeholder, {})}
          >
            {appSettings.text.newPostBoxLabel ||
              t('groups-web.discussion.create-post.placeholder')}
          </Text>
          <PluginsIconBar mobile={mobile} />
        </>
      )}
    </Box>
  );

  function getDraftText() {
    if (!draft) {
      return '';
    }

    return draft.blocks
      .filter(block => block.type === 'unstyled')
      .map(block => block.text)
      .join('\n');
  }
};

NewPostComponent.defaultProps = {
  currentSiteMember: Anonymous as SiteMember,
};

const enhance = compose(
  translate(),
  WithGroup,
  withTpaComponentsConfig,
  withAppSettings,
  withSiteMembers,
);

export const NewPost = enhance(NewPostComponent) as React.ComponentType<
  NewPostBoxProps
>;
