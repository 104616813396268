import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Search } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { isValidEmail } from '@wix/social-groups-common/dist/src/utils';
import { Grid } from 'wix-ui-tpa/Grid';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import {
  isGroupSecret,
  isPendingGroup,
  memberWrapper,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { MemberCard } from '../../MemberCard/MemberCard';
import { withAppData, WithAppDataProps, withSiteMembers } from '../../Context';
import { getMembersKey } from '../../../MembersLabel/MembersLabel';
import { WithSiteMembers } from '../../Context/withSiteMembers';
import { EmptyState } from '../../EmptyState';
import { LetterIcon } from '../../../icons/LetterIcon';
import {
  InviteMembersProps,
  withInviteMembers,
} from '../../Context/InviteMembersProps';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import { CanNotAddMembersModal } from '../CanNotAddMemebersModal';
import { MembersModal } from '../MembersModal';

import styles from './AddMembersModal.st.css';
import commonMembersStyles from '../MembersModal/MembersModal.st.css';

const INVITE_SENT_TIMEOUT = 1000;

export interface AddMembersModalProps {
  isOpen: boolean;
  group: ApiTypes.v1.GroupResponse;
  currentMember: ApiTypes.v1.GroupMemberResponse;

  handleClose();
}

type AddMembersProps = AddMembersModalProps &
  InjectedTranslateProps &
  WithSiteMembers &
  InviteMembersProps &
  WithAppDataProps &
  WithBiLoggerProps &
  WithTpaComponentsConfigProps &
  InjectedExperimentsProps;

interface AddMembersModalState {
  searchQuery: string;
  invitesMap: { [email: string]: number };
}

class AddMembersModalComponent extends React.Component<
  AddMembersProps,
  AddMembersModalState
> {
  static defaultProps = {
    siteMembers: [],
  };

  state = {
    searchQuery: '',
    invitesMap: {},
  };

  componentDidUpdate(prevProps: Readonly<AddMembersProps>) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({ searchQuery: '' });
    }
  }

  setSearchQuery = (value: string) => {
    this.setState({ searchQuery: value });
  };

  render() {
    const { t, isOpen, handleClose, group, mobile, experiments } = this.props;
    const { searchQuery } = this.state;
    const membersLabel = t(getMembersKey(group));
    const title = t('groups-web.add.members.title', { membersLabel });
    const siteMembers = this.getSiteMembers();

    if (
      experiments.enabled('specs.groups.AddMembersPendingGroup') &&
      isPendingGroup(group)
    ) {
      return <CanNotAddMembersModal isOpen={isOpen} onClose={handleClose} />;
    }

    return (
      <MembersModal isOpen={isOpen} onRequestClose={handleClose}>
        <MembersModal.Header>{title}</MembersModal.Header>
        <div {...styles('searchWrapper', { mobile })}>
          <Search
            onChange={this.setSearchQuery}
            withBorder={!mobile}
            fullWidth={true}
            forceOpen={true}
            placeholder={this.getSearchPlaceholder()}
            withCloseButton={!!searchQuery}
          />
        </div>
        <MembersModal.Content>
          {this.renderMembers(siteMembers)}
        </MembersModal.Content>
      </MembersModal>
    );
  }

  getSearchPlaceholder = () => {
    const { t, group } = this.props;
    const isSecret = isGroupSecret(group);
    const siteMembers = this.getSiteMembers();

    let key = '';
    if (isSecret) {
      key = 'groups-web.secret-group.add.members.search.placeholder';
    } else {
      key = 'groups-web.add.members.search.placeholder';
    }
    return t(key, { count: siteMembers.length });
  };

  addMember = memberId => {
    const { biLogger, group } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupsAddMemberClicked({
        origin: 'modal_plus_add_btn',
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
      });
    });
    return this.props.addMembers(this.props.group, [memberId]);
  };

  inviteMemberByEmail = () => {
    const { inviteMemberByEmail, biLogger } = this.props;
    const { searchQuery } = this.state;
    tryToCallBi(async () => {
      await biLogger.inviteSent({
        group_id: this.props.group.groupId,
        site_member_id: null,
        contact: searchQuery,
        origin: 'dialog_scr_invite_btn',
      } as any);
    });
    this.addEmailToInvitesSent(searchQuery);
    inviteMemberByEmail(searchQuery);
  };

  private addEmailToInvitesSent(email: string) {
    const { invitesMap } = this.state;
    const timeout = () => {
      return setTimeout(() => {
        this.removeEmailFromInvitesSent(email);
      }, INVITE_SENT_TIMEOUT);
    };
    this.setState({ invitesMap: { ...invitesMap, [email]: timeout() } });
  }

  private removeEmailFromInvitesSent(email: string) {
    const { invitesMap } = this.state;
    this.setState({ invitesMap: { ...invitesMap, [email]: null } });
  }

  private renderMembers(siteMembers: ApiTypes.v1.GroupMemberResponse[]) {
    const { group } = this.props;

    if (!siteMembers.length) {
      return [
        <Grid.Item colSpan={1} rowSpan={1} key={'no-members'}>
          {isValidEmail(this.state.searchQuery) && !isGroupSecret(group)
            ? this.renderInvites()
            : this.renderNoSiteMembersState()}
        </Grid.Item>,
      ];
    }
    return siteMembers.map((member, i) => {
      const { t, mobile, membersUpdate } = this.props;
      const { name, imageUrl, relationship } = memberWrapper(member);
      const memberId = member.siteMemberId;
      const updating = membersUpdate && membersUpdate.includes(memberId);
      return (
        <div
          {...commonMembersStyles('memberCardWrapper', { mobile })}
          key={member.siteMemberId}
        >
          <Grid.Item colSpan={1} rowSpan={1}>
            <MemberCard
              name={name.nick || t('groups-web.member.anonymous')}
              image={imageUrl}
              done={relationship !== ApiTypes.v1.RelationshipWithGroup.NONE}
              onActionClick={() => this.addMember(memberId)}
              actionLabel={t('groups-web.add')}
              actionDoneLabel={t('groups-web.added')}
              withDivider={!mobile && i !== siteMembers.length - 1}
              updating={updating}
            />
          </Grid.Item>
        </div>
      );
    });
  }

  private renderInvites() {
    const { t } = this.props;
    const { invitesMap, searchQuery } = this.state;
    return (
      <MemberCard
        name={this.state.searchQuery}
        image={<LetterIcon className={styles.inviteIcon} />}
        done={!!invitesMap[searchQuery]}
        onActionClick={this.inviteMemberByEmail}
        actionLabel={t('groups-web.invite')}
        actionDoneLabel={t('groups-web.invited')}
      />
    );
  }

  private renderNoSiteMembersState() {
    const { t, group } = this.props;
    const { searchQuery } = this.state;
    let emptySearchMachKey = '';
    if (isGroupSecret(group)) {
      emptySearchMachKey =
        'groups-web.secret-group.members.empty-search-match.caption';
    } else {
      emptySearchMachKey = 'group-web.members.empty-search-match.caption';
    }
    return (
      <EmptyState
        title={searchQuery ? null : t('group-web.members.empty')}
        content={t(
          searchQuery ? emptySearchMachKey : 'group-web.members.empty.caption',
        )}
      />
    );
  }

  private getSiteMembers() {
    const { siteMembers, currentMember } = this.props;
    const { searchQuery } = this.state;
    const { siteMemberId } = currentMember;
    return siteMembers.filter(
      m =>
        m.siteMemberId !== siteMemberId &&
        m.name &&
        m.name.nick &&
        m.name.nick
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase()),
    );
  }
}

const enhance = compose(
  translate(),
  withSiteMembers,
  withBiLogger,
  withAppData,
  withInviteMembers,
  withTpaComponentsConfig,
  withExperiments,
);

export const AddMembersModal = enhance(
  AddMembersModalComponent,
) as React.ComponentType<AddMembersModalProps>;
