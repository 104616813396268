import { SocialApiTypes } from '@wix/social-groups-api/dist/src/types';
import * as uuid from 'uuid/v4';

import { serializable, object } from 'serializr';

import { IAttachment } from './types';

class PixelDimensions implements SocialApiTypes.PixelDimensions {
  @serializable
  width: number;

  @serializable
  height: number;
}

export class Attachment implements IAttachment {
  @serializable
  id: string;

  @serializable
  mimeType: string;

  @serializable
  uri: string;

  @serializable
  mediaType: SocialApiTypes.MediaType;

  @serializable(object(PixelDimensions))
  pixelDimensions: SocialApiTypes.PixelDimensions;

  constructor(attachment: SocialApiTypes.Resource) {
    this.id = attachment.id || uuid();
    this.uri = attachment.uri;
    this.mediaType = attachment.mediaType;
    this.mimeType = attachment.mimeType;
    this.pixelDimensions = attachment.pixelDimensions || {};
  }
}
