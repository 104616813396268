import * as React from 'react';

import { translate, InjectedTranslateProps } from 'react-i18next';

import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

import { canInviteMembers, isGroupMember } from '@wix/social-groups-api';
import { AddMembersModal } from '../../modals/AddMemberModal';
import {
  WithGroupProps,
  WithGroup,
  WithGroupActions,
  WithGroupActionProps,
} from '../../Context';
import {
  withBiLogger,
  WithBiLoggerProps,
  tryToCallBi,
  BIUserEntry,
} from '@wix/social-groups-common/dist/src/context';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { getMembersKey } from '../../../MembersLabel/MembersLabel';
import { TextButton } from '@wix/social-groups-common/dist/src/components';

export interface MembersWidgetCTAButtonState {
  isModalOpen: boolean;
}

export class MembersWidgetCTAButtonComponent extends React.Component<
  InjectedTranslateProps &
    WithGroupProps &
    WithGroupActionProps &
    WithBiLoggerProps,
  MembersWidgetCTAButtonState
> {
  readonly state: MembersWidgetCTAButtonState = {
    isModalOpen: false,
  };

  handleModalClose = () => this.setState({ isModalOpen: false });

  handleModalOpen = () => this.setState({ isModalOpen: true });

  render() {
    const {
      t,
      members,
      changeTab,
      group,
      currentMember,
      biLogger,
    } = this.props;
    const { isModalOpen } = this.state;
    const groupMembers = members.filter(isGroupMember);

    let action = () => {
      tryToCallBi(async () => {
        await biLogger.groupsTabClicked({
          group_id: group.groupId,
          origin: 'see_all_members_link',
          name: 'members',
        });
      });
      changeTab('members');
    };

    const membersLabel = t(getMembersKey(group));
    let label = t('groups-web.discussion.members.see-all', {
      total: groupMembers.length,
      membersLabel,
    });

    if (canInviteMembers(group)) {
      label = t('groups-web.discussion.members.add', { membersLabel });
      action = () => {
        tryToCallBi(async () => {
          await biLogger.groupsAddMemberClicked({
            origin: 'add_members_link',
            groupId: group.groupId,
            userEntry: BIUserEntry.SITE,
          });
        });
        this.handleModalOpen();
      };
    }

    return (
      <>
        <TextButton onClick={action} priority={TEXT_BUTTON_PRIORITY.primary}>
          {label}
        </TextButton>

        <AddMembersModal
          group={group}
          isOpen={isModalOpen}
          currentMember={currentMember}
          handleClose={this.handleModalClose}
        />
      </>
    );
  }
}

const enhance = compose(translate(), WithGroup, WithGroupActions, withBiLogger);

export const MembersWidgetCTAButton = enhance(
  MembersWidgetCTAButtonComponent,
) as React.ComponentType;
