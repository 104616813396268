import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Box,
  Header,
  InfoCard,
  InfoCardProps,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import classnames from 'classnames';
import { withActivity, WithActivityProps } from '../../Context/withActivity';
import { NewMembersProps, withNewMembers } from '../../Context/withNewMembers';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../Context/withAppSettings';
import { getMembersKey } from '../../../MembersLabel/MembersLabel';
import * as moment from 'moment';

import activityStyles from './GroupActivity.st.css';
import styles from '../About.st.css';

interface GroupProps {
  group: ApiTypes.v1.GroupResponse;
}

export interface GroupActivityProps
  extends InjectedTranslateProps,
    WithActivityProps,
    GroupProps,
    NewMembersProps,
    WithTpaComponentsConfigProps,
    WithAppSettingsProps {
  group: ApiTypes.v1.GroupResponse;
}

const GroupActivityCard: React.FC<InfoCardProps> = ({
  title,
  subtitle,
  withPlaceholder,
}) => (
  <div className={activityStyles.activityWrapper}>
    <InfoCard
      className={activityStyles.activity}
      title={title}
      subtitle={subtitle}
      withPlaceholder={withPlaceholder}
    />
  </div>
);

const GroupActivityDivider = () => (
  <div className={activityStyles.divider}></div>
);

class GroupActivityComponent extends React.Component<GroupActivityProps> {
  componentDidMount(): void {
    this.getActivityStats();
  }

  //TODO: call this when real reactions data will be provided
  renderReactionsStats() {
    const { t } = this.props;
    return (
      <>
        <GroupActivityDivider />
        <GroupActivityCard
          title={'42'}
          subtitle={t('groups-web.activity.reactions')}
        />
      </>
    );
  }

  render() {
    const {
      t,
      activities,
      group,
      newMembers,
      mobile,
      appSettings,
    } = this.props;
    const membersLabel = t(getMembersKey(group));

    if (!appSettings.aboutDisplay.showActivity) {
      return null;
    }

    return (
      <Box {...styles('root')}>
        <div
          className={classnames(
            styles.headerWrapper,
            mobile ? '' : activityStyles.headerWrapper,
          )}
        >
          <Header className={styles.header}>
            {t('groups-web.activity')}:
            <span className={styles.faded}>
              {' '}
              {t('groups-web.lastNDays', { N: 30 })}
            </span>
          </Header>
        </div>
        <div {...activityStyles('activities', { mobile }, this.props)}>
          <GroupActivityCard
            title={activities && `${activities.newPosts}`}
            subtitle={t('groups-web.activity.posts')}
            withPlaceholder
          />
          <GroupActivityDivider />
          <GroupActivityCard
            title={newMembers !== null && newMembers >= 0 && `${newMembers}`}
            subtitle={t('groups-web.activity.members', { membersLabel })}
            withPlaceholder
          />
        </div>
      </Box>
    );
  }

  private getActivityStats() {
    try {
      const { activityActions, getNewMembers, group } = this.props;
      const { groupId } = group;
      activityActions.getActivity(groupId);
      getNewMembers(
        groupId,
        moment()
          .subtract(1, 'months')
          .toDate(),
      );
    } catch (e) {
      console.error('Get group activity: FAIL');
    }
  }
}

const enhance = compose(
  withNewMembers,
  withActivity,
  withTpaComponentsConfig,
  withAppSettings,
  translate(),
);

export const GroupActivity: React.ComponentType<GroupProps> = enhance(
  GroupActivityComponent,
);
