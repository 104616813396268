import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { canSeeFeed, canWritePost } from '@wix/social-groups-api';
import {
  FeedApiTypes,
  SocialApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { RawDraftContentState } from 'wix-rich-content-editor-common';
import { MENTION_TYPE } from 'wix-rich-content-plugin-mentions/dist/module.viewer.cjs';
import { Grid } from 'wix-ui-tpa/Grid';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';

import {
  MembershipChangeAction,
  withAppSettings,
  WithAppSettingsProps,
  WithGroup,
  WithGroupProps,
  withMembershipChangeAction,
} from '../Context';

import { About } from './About';
import { Feed, FeedProps } from './Feed';
import { MembersWidget } from './MembersWidget';
import { NewPost } from './NewPost';
import { PostById } from './PostById';

import styles from './Discussion.st.css';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import { NewPostModal } from '../modals/NewPostModal';

export interface DiscussionProps {
  feedItemId?: string;
  forceCreatePost?: boolean;

  resetForceCreatePost?(): void;
}

interface DiscussionState {
  isWritePostModalOpened: boolean;
  renderPostModal: boolean;
  isFeedItemCreating: boolean;
  draft: RawDraftContentState;
}

function getMentions(content: RawDraftContentState): SocialApiTypes.Mention[] {
  return Object.values(content.entityMap as { [key: string]: any })
    .filter(entity => entity.type === MENTION_TYPE)
    .map(entity => ({
      identity: {
        identityId: entity.data.mention.id,
      },
    }));
}

type DiscussionComponentProps = InjectedTranslateProps &
  DiscussionProps &
  WithGroupProps &
  WithTpaComponentsConfigProps &
  WithAppSettingsProps &
  MembershipChangeAction &
  WithBiLoggerProps;

export class DiscussionComponent extends React.Component<
  DiscussionComponentProps,
  DiscussionState
> {
  readonly state: DiscussionState = {
    renderPostModal: false,
    isWritePostModalOpened: false,
    isFeedItemCreating: false,
    draft: null,
  };

  static getDerivedStateFromProps(
    nextProps,
    prevState,
  ): Partial<DiscussionState> {
    const { isFeedItemCreating } = nextProps.feed;
    const nextState: Partial<DiscussionState> = {
      isFeedItemCreating,
    };
    if (isFeedItemCreating === false && prevState.isFeedItemCreating === true) {
      nextState.isWritePostModalOpened = false;
    }
    return nextState;
  }

  componentDidMount(): void {
    const { forceCreatePost, resetForceCreatePost } = this.props;
    if (forceCreatePost && resetForceCreatePost) {
      resetForceCreatePost();
    }

    tryToCallBi(async () => {
      await this.props.biLogger.groupFeedView({
        clubId: this.props.group.groupId,
      });
    });
  }

  componentDidUpdate(prevProps: Readonly<DiscussionComponentProps>): void {
    if (!this.props.wixCodeApiParams && this.props.setWixCodeApiParams) {
      this.props.setWixCodeApiParams();
    }
  }

  getFeedEntity = (
    content: RawDraftContentState,
    attachments?,
  ): FeedApiTypes.FeedItemEntity => {
    return {
      body: {
        content: JSON.stringify(content),
        contentType: SocialApiTypes.ContentType.DRAFTJS,
      },
      attachments,
      mentions: getMentions(content),
    };
  };

  reactFeedItem = (feedItemId, reaction) => {
    this.props.feed.reactFeedItem(feedItemId, reaction);
  };

  unreactFeedItem = (feedItemId, reactionCode) => {
    this.props.feed.unreactFeedItem(feedItemId, reactionCode);
  };

  handleCreatePost = (content: RawDraftContentState, attachments?) =>
    this.props.feed.createFeedItem(this.getFeedEntity(content, attachments));

  handleUpdatePost = (
    feedItemId: string,
    content: RawDraftContentState,
    attachments?,
  ) =>
    this.props.feed.updateFeedItem(
      feedItemId,
      this.getFeedEntity(content, attachments),
    );

  handleDeletePost = (feedItemId: string) =>
    this.props.feed.deleteFeedItem(feedItemId);

  handlePinPost = (feedItemId: string) =>
    this.props.feed.pinFeedItem(feedItemId);

  handleUnpinPost = (feedItemId: string) =>
    this.props.feed.unpinFeedItem(feedItemId);

  handleFollowPost = (feedItemId: string) =>
    this.props.feed.followFeedItem(feedItemId);

  handleUnfollowPost = (feedItemId: string) =>
    this.props.feed.unfollowFeedItem(feedItemId);

  openNewPostModal = () => this.toggleNewPostModal(true);

  toggleNewPostModal = (isWritePostModalOpened: boolean) => {
    return this.setState({ isWritePostModalOpened, renderPostModal: true });
  };

  isPostByIdPage = () => !!this.props.feedItemId;

  render() {
    const { group, mobile, forceCreatePost, appSettings, apps } = this.props;
    const { draft } = this.state;

    if (forceCreatePost) {
      this.maybeWritePost();
    }

    return (
      <>
        <Grid
          maxColumns={mobile ? 1 : 3}
          columnGap={appSettings.generalLayout.layoutSpacing}
          {...styles('root', { mobile })}
        >
          <Grid.Item colSpan={mobile ? 1 : 2}>
            <div className={styles.container}>
              {this.isPostByIdPage() ? null : (
                <NewPost
                  onClick={() => this.maybeWritePost('top_rce_area')}
                  draft={draft}
                />
              )}
              {this.renderContent()}
            </div>
          </Grid.Item>
          {!mobile ? (
            <Grid.Item>
              <div className={styles.container}>
                <About group={group} apps={apps} />
                <MembersWidget />
              </div>
            </Grid.Item>
          ) : null}
        </Grid>
        {this.renderNewPostModal()}
      </>
    );
  }

  handleDraftSave = draft => this.setState({ draft });

  private readonly maybeWritePost = (biOrigin?: string) => {
    const {
      group,
      isLoggedIn,
      promptLogin,
      openJoinDialog,
      biLogger,
    } = this.props;
    if (biOrigin) {
      tryToCallBi(async () => {
        await biLogger.groupCreatePostClick({
          group_id: group.groupId,
          origin: biOrigin,
        });
      });
    }
    //logged in?
    if (!isLoggedIn) {
      return promptLogin();
    }
    //has member permissions
    if (canWritePost(group)) {
      this.openNewPostModal();
    } else {
      openJoinDialog();
    }
  };

  private renderNewPostModal() {
    const {
      renderPostModal,
      isWritePostModalOpened,
      isFeedItemCreating,
      draft,
    } = this.state;

    return (
      renderPostModal && (
        <NewPostModal
          initialContentState={draft}
          isPostPublishing={isFeedItemCreating}
          isOpen={isWritePostModalOpened}
          onVisibilityChange={this.toggleNewPostModal}
          onSubmit={this.handleCreatePost}
          onSaveDraft={this.handleDraftSave}
        />
      )
    );
  }

  private renderContent() {
    const { group } = this.props;
    if (canSeeFeed(group)) {
      return this.renderFeed();
    }
    return null;
  }

  private renderFeed() {
    const { feed, t } = this.props;
    const postActions = {
      onDeletePostClick: this.handleDeletePost,
      onPinPostClick: this.handlePinPost,
      onUnpinPostClick: this.handleUnpinPost,
      onFollowPostClick: this.handleFollowPost,
      onUnfollowPostClick: this.handleUnfollowPost,
      onUpdatePostClick: this.handleUpdatePost,
      react: this.reactFeedItem,
      unreact: this.unreactFeedItem,
    };

    if (feed.feedLoading) {
      return <Spinner offset="L" label={t('groups-web.discussion.loading')} />;
    }

    const feedItems = feed.feedItems.filter(
      feedItem =>
        // activity post
        feedItem.activity ||
        // user's post
        (feedItem.entity &&
          feedItem.entity.body.contentType ===
            SocialApiTypes.ContentType.DRAFTJS),
    );

    if (this.isPostByIdPage()) {
      const feedItemById = feedItems.find(
        feedItem => feedItem.feedItemId === this.props.feedItemId,
      );
      return (
        <PostById
          feedItem={feedItemById}
          {...postActions}
          contextToken={feed.contextToken}
        />
      );
    }

    return (
      <Feed
        hasMore={!!feed.cursor}
        fetchMore={feed.fetchMore}
        feedItems={feedItems}
        onCreatePostClick={() => this.maybeWritePost('discussion_tab_btn')}
        contextToken={feed.contextToken}
        {...(postActions as FeedProps)}
      />
    );
  }
}

const enhance = compose(
  translate(),
  WithGroup,
  withTpaComponentsConfig,
  withAppSettings,
  withMembershipChangeAction,
  withBiLogger,
);

export const Discussion = enhance(DiscussionComponent) as React.ComponentType<
  DiscussionProps
>;

export default Discussion;
