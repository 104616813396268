import * as React from 'react';
import {
  getMembersLabelKey,
  MembersLabelTranslations,
} from '@wix/social-groups-common/dist/src/settings';
import { InjectedTranslateProps } from 'react-i18next';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

export interface MembersLabelProps extends InjectedTranslateProps {
  group: ApiTypes.v1.GroupResponse;
}
export const MembersLabelDict: MembersLabelTranslations = {
  Members: 'groups-web.members',
  Students: 'groups-web.members.students',
  Coworkers: 'groups-web.members.coworkers',
  Friends: 'groups-web.members.friends',
  Gamers: 'groups-web.members.gamers',
  Custom: 'groups-web.members.custom',
};

export function getMembersKey(group: ApiTypes.v1.GroupResponse) {
  return getMembersLabelKey(group, MembersLabelDict);
}
