import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Block,
  BlockAlign,
  BlockFlow,
  Box,
  Header,
  WebOut,
  TextButton,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { canEdit, Group } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  allTypeMappers,
  ContentViewer,
  pluginsConfig,
  UpdateProgress,
} from '@wix/social-groups-common/dist/src/components/ContentEditor';
import {
  BIUserEntry,
  MemberInvites,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
  withMemberInvites,
} from '@wix/social-groups-common/dist/src/context';
import {
  countChars,
  editorStateFromRawContent,
  editorStateToString,
  parseContentStateString,
} from '@wix/social-groups-common/dist/src/components/ContentEditor/utils';
import { AboutEditor } from './AboutEditor';
import { GroupUpdate, withGroupUpdate } from '../Context/GroupUpdate';
import { EditorState } from 'wix-rich-content-editor-common';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import getTheme from '../../ContentEditor/theme';
import styles from './About.st.css';

const typeMappers = allTypeMappers();
export const ABOUT_GROUP = 'about-group';

interface AboutProps {
  group: ApiTypes.v1.GroupResponse;
  updateProgress: UpdateProgress;
}

type Props = AboutProps &
  InjectedTranslateProps &
  GroupUpdate &
  WithTpaComponentsConfigProps &
  WithBiLoggerProps &
  MemberInvites;

interface AboutComponentState {
  editing: boolean;
}

class AboutComponent extends React.Component<Props, AboutComponentState> {
  state: AboutComponentState = {
    editing: false,
  };
  editMode = () => {
    const { biLogger, group } = this.props;
    this.setState({ editing: true });
    tryToCallBi(async () => {
      await biLogger.groupsAboutEditClicked({
        groupId: group.groupId,
        origin: 'edit_descr_link',
        userEntry: BIUserEntry.SITE,
      });
    });
    this.props.setEditMode(UpdateProgress.STARTED);
  };

  viewMode = () => {
    this.setState({ editing: false });
    this.props.setEditMode(UpdateProgress.STALE);
  };

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { t, group, mobile } = this.props;
    const titleLabel = t('groups-web.about');

    const editing = this.editing();
    const editButton =
      canEdit(group) && !editing ? (
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={this.editMode}
        >
          {t('groups-web.viewer.about.edit')}
        </TextButton>
      ) : null;
    return (
      <Block align={BlockAlign.start} flow={BlockFlow.row}>
        <Box withTopBottomBorders={!mobile} withSideBorders={!mobile}>
          <Block end={editButton}>
            <Header className={styles.header} data-hook={ABOUT_GROUP}>
              {titleLabel}
            </Header>
          </Block>
          {editing ? this.renderEditor() : this.renderViewer()}
        </Box>
      </Block>
    );
  }

  private editing() {
    return this.state.editing;
  }

  private readonly updateAbout = (editorState: EditorState) => {
    const { biLogger, group } = this.props;
    const text = editorStateToString(editorState);
    const g = Group.getGroupResponseWrapper(this.props.group);
    tryToCallBi(async () => {
      await biLogger.groupsAboutEditSave({
        groupId: group.groupId,
        length: countChars(editorState.getCurrentContent()),
        origin: 'modal_scrn_save_btn',
        userEntry: BIUserEntry.SITE,
      });
    });
    const { details } = g.setDescription(text);
    // TODO: FIX IT!!!!!1111oneoneone
    this.props.updateGroup(g.getUpdatePaths(), details);
    this.viewMode();
  };

  private renderViewer(): React.ReactNode {
    const contentState = this.getRawContentState();
    return (
      <ContentViewer
        theme={getTheme()}
        initialState={contentState}
        config={pluginsConfig}
        typeMappers={typeMappers}
      />
    );
  }

  private renderEditor() {
    const { mobile } = this.props;
    return mobile ? (
      this.renderWebOut()
    ) : (
      <AboutEditor
        editorState={this.getEditorState()}
        saveChanges={this.updateAbout}
        cancel={this.viewMode}
        loading={this.props.updateProgress === UpdateProgress.UPDATING}
      />
    );
  }

  private renderWebOut() {
    const { t, memberInvitesLink, loadMemberInvitesLink } = this.props;
    return (
      <WebOut
        buttonLabelKey={t('groups-web.web-out.button')}
        inviteLink={memberInvitesLink}
        loadMemberInvitesLink={loadMemberInvitesLink}
        title={t('groups-web.web-out.about.edit.title')}
        caption={t('groups-web.web-out.description')}
        isOpen={true}
        onRequestClose={this.viewMode}
      />
    );
  }

  private getEditorState() {
    const raw = this.getRawContentState();
    return editorStateFromRawContent(raw);
  }

  private getRawContentState() {
    const g = Group.getGroupResponseWrapper(this.props.group);
    const description = g.getDescription();
    return parseContentStateString(description);
  }
}

const enhance = compose(
  withGroupUpdate,
  translate(),
  withTpaComponentsConfig,
  withMemberInvites,
  withBiLogger,
);

export const About = enhance(AboutComponent) as React.ComponentType<AboutProps>;
