import * as React from 'react';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Modal, Button } from '@wix/social-groups-common/dist/src/components';
import { InjectedTranslateProps, translate } from 'react-i18next';

interface DeletePostModalProps {
  isOpen: boolean;

  onClose();

  onDelete();
}

const DeletePostModalComponent: React.FC<DeletePostModalProps &
  InjectedTranslateProps> = ({ isOpen, onClose, t, onDelete }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header
        title={t('groups-web.discussion.delete-post-popup.title')}
        subtitle={t('groups-web.discussion.delete-post-popup.subtitle')}
      />
      <Modal.Buttons>
        <Button priority={PRIORITY.secondary} onClick={onClose}>
          {t('groups-web.discussion.delete-post-popup.cancel')}
        </Button>
        <Button priority={PRIORITY.primary} onClick={onDelete}>
          {t('groups-web.discussion.delete-post-popup.delete')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export const DeletePostModal = translate()(
  DeletePostModalComponent,
) as React.ComponentType<DeletePostModalProps>;
