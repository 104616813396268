import * as React from 'react';

import { InjectedTranslateProps, translate } from 'react-i18next';

import { AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

import {
  UserCard,
  TextButton,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  canInviteMembers,
  isGroupMember,
  isPendingGroup,
  memberWrapper,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { WithGroup, WithGroupProps, withSiteMembers } from '../../Context';
import { WithSiteMembers } from '../../Context/withSiteMembers';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { CanNotAddMembersModal } from '../../modals/CanNotAddMemebersModal';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';

import styles from './MembersWidget.st.css';

function memberFilters(currentMember: ApiTypes.v1.GroupMemberResponse) {
  return {
    isNotCurrentUser(member: ApiTypes.v1.GroupMemberResponse) {
      return currentMember.siteMemberId !== member.siteMemberId;
    },
    isNotGroupMember(member: ApiTypes.v1.GroupMemberResponse) {
      return member.relationship === ApiTypes.v1.RelationshipWithGroup.NONE;
    },
    shuffle() {
      return Math.random() - 0.5;
    },
  };
}

interface MembersListState {
  isModalOpen: boolean;
}

type Props = InjectedTranslateProps &
  WithGroupProps &
  WithSiteMembers &
  WithBiLoggerProps &
  InjectedExperimentsProps;

export class MembersListComponent extends React.Component<
  Props,
  MembersListState
> {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    this.props.getFollowingMembers(this.props.currentSiteMember.id);
  }

  handleMemberAddClick(siteMemberId: string) {
    const { biLogger, group, experiments } = this.props;
    return () => {
      tryToCallBi(async () => {
        await biLogger.groupsAddMemberClicked({
          groupId: group.groupId,
          origin: 'widget_plus_add_btn',
          userEntry: BIUserEntry.SITE,
        });
      });
      if (
        experiments.enabled('specs.groups.AddMembersPendingGroup') &&
        isPendingGroup(group)
      ) {
        this.openModal();
      } else {
        this.props.addMembers(group, [siteMemberId]);
      }
    };
  }

  handleMemberFollowClick(siteMemberId: string) {
    const { biLogger, group } = this.props;
    return () => {
      tryToCallBi(async () => {
        await biLogger.followMember({
          group_id: group.groupId,
          site_member_id: siteMemberId,
          origin: 'discussion_tab_link_clk',
          userEntry: BIUserEntry.SITE,
          action: 'follow',
        });
      });

      this.props.followMember(siteMemberId);
    };
  }

  handleMemberUnfollowClick(siteMemberId: string) {
    const { biLogger, group } = this.props;
    return () => {
      tryToCallBi(async () => {
        await biLogger.followMember({
          group_id: group.groupId,
          site_member_id: siteMemberId,
          origin: 'discussion_tab_link_clk',
          userEntry: BIUserEntry.SITE,
          action: 'unfollow',
        });
      });

      this.props.unfollowMember(siteMemberId);
    };
  }

  navigateToUserProfile(siteMemberId: string) {
    return () => this.props.openUserProfile(siteMemberId);
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      t,
      members: groupMembers,
      currentMember,
      siteMembers,
      group,
      followingMembers,
    } = this.props;

    const { isModalOpen } = this.state;

    const canInvite = canInviteMembers(group);
    const filters = memberFilters(currentMember);
    let filtered: ApiTypes.v1.GroupMemberResponse[];

    if (canInvite) {
      filtered = siteMembers
        .filter(filters.isNotCurrentUser)
        .filter(filters.isNotGroupMember)
        .sort(filters.shuffle)
        .slice(0, 5);
    } else {
      filtered = groupMembers
        .filter(isGroupMember)
        .filter(filters.isNotCurrentUser)
        .sort(filters.shuffle)
        .slice(0, 5);
    }

    if (!filtered.length) {
      return (
        <Text
          tagName="p"
          typography={TYPOGRAPHY.runningText}
          {...styles(styles.empty)}
        >
          {t('groups-web.discussion.members.empty')}
        </Text>
      );
    }

    return (
      <>
        {filtered.map(m => {
          const {
            imageUrl,
            name: { nick },
            siteMemberId,
          } = memberWrapper(m);
          const isFollowing = followingMembers.includes(siteMemberId);

          return (
            <div className={styles.memberWrapper} key={siteMemberId}>
              <UserCard
                className={styles.userCard}
                name={nick || t('groups-web.member.anonymous')}
                pic={imageUrl}
                avatarSize={AvatarSize.small}
                onUserClick={this.navigateToUserProfile(siteMemberId)}
                userAction={
                  <TextButton
                    onClick={
                      canInvite
                        ? this.handleMemberAddClick(siteMemberId)
                        : isFollowing
                        ? this.handleMemberUnfollowClick(siteMemberId)
                        : this.handleMemberFollowClick(siteMemberId)
                    }
                    priority={TEXT_BUTTON_PRIORITY.primary}
                  >
                    {canInvite
                      ? t('groups-web.add')
                      : isFollowing
                      ? t('groups-web.following')
                      : t('groups-web.follow')}
                  </TextButton>
                }
              />
            </div>
          );
        })}
        <CanNotAddMembersModal isOpen={isModalOpen} onClose={this.closeModal} />
      </>
    );
  }
}

const enhance = compose(
  translate(),
  WithGroup,
  withSiteMembers,
  withBiLogger,
  withExperiments,
);

export const MembersList = enhance(MembersListComponent);
